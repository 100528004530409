import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import useWindowSize from '../../components/hooks/useWindowSize';
import useScrollToElement from '../../components/hooks/useScrollToElement';
import { TOKEN_NAME } from '../../helpers/constants/constants';
import { RiFacebookCircleLine, RiInstagramLine, RiTelegramLine, RiTwitterXLine } from 'react-icons/ri';
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { LuUserPlus2 } from "react-icons/lu";
import { ApiService } from '../../helpers/services/apiSrevice';
import AlertService from '../../helpers/services/alertService';
import { LuTimer } from "react-icons/lu";
import { FaRegCircleCheck } from "react-icons/fa6";




export default function Tasks() {

  const [tasksList, setTasksList] = useState([]);
  const windowSize = useWindowSize();
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (windowSize?.width <= 991) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [windowSize]);

  useScrollToElement("tasks", shouldScroll, 180);


  const [userName, setUserName] = useState("");
  const [buttonSpinnerId, setButtonSpinnerId] = useState(null);
  const [isShowModalSinner, setIsShowModalSinner] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  useEffect(() => {
    getTasks();
  }, [])

  const getTasks = () => {
    ApiService.getTasks().then(response => {
      if (response && response.data) {
        setTasksList(response.data)
      }
    }).catch(error => AlertService.alert("error", error))
  }


  const getButton = (item) => {
    if (!item) { return false; }
    switch (item.status) {
      case 0:
        return <Button
          type='button'
          variant='primary'
          className='fw-600 px-3 d-flex align-items-center justify-content-center gap-2 py-1 mt-2 mt-md-0'
          style={{ minWidth: "130px" }}
          onClick={() => setCurrentTask(item)}
        >
          <span>Follow</span>
          <LuUserPlus2 size={20} />
        </Button>
      case 1:
        if (buttonSpinnerId === item.id) {
          return <Button
            type='button'
            variant='success'
            className='fw-600 px-3 d-flex align-items-center justify-content-center gap-2 py-1 mt-2 mt-md-0'
            style={{ minWidth: "130px" }}
            disabled
          >
            <span>Loading...</span>
            <Spinner animation="border" size="sm" />
          </Button>
        } else {
          return <Button
            type='button'
            variant='success'
            className='fw-600 px-3 d-flex align-items-center justify-content-center gap-2 py-1 mt-2 mt-md-0'
            style={{ minWidth: "130px" }}
            onClick={() => onClaim(item)}
          >
            <span>Claim</span>
            <LiaMoneyBillWaveSolid size={24} />
          </Button>
        }
      case 2:
        return <Button
          type='button'
          variant='primary'
          disabled
          className='px-3 d-flex align-items-center justify-content-center gap-2 py-1 mt-2 mt-md-0'
          style={{ minWidth: "130px" }}
        >
          <span>Pending</span>
          <LuTimer size={18} />
        </Button>
      case 3:
        return <Button
          type='button'
          variant='secondary'
          disabled
          className='px-3 d-flex align-items-center justify-content-center gap-2 py-1 mt-2 mt-md-0'
          style={{ minWidth: "130px" }}
        >
          <span>Done</span>
          <FaRegCircleCheck size={16} />
        </Button>
      default:
        break;
    }
  }

  const getIcon = (task) => {
    if (!task) { return false; }
    switch (task.name) {
      case "Facebook":
        return <RiFacebookCircleLine size={30} />
      case "Instagram":
        return <RiInstagramLine size={30} />
      case "X (Twitter)":
        return <RiTwitterXLine size={27} />
      case "Telegram":
        return <RiTelegramLine size={30} />

      default:
        break;
    }
  }

  const cancel = () => {
    setCurrentTask(false)
    setUserName("")
  }

  const onFollow = (event) => {
    event && event.preventDefault();
    if (!currentTask) { return false; }
    setIsShowModalSinner(true);
    let data = {
      socialMediaId: currentTask.id,
      socialMediaUsername: userName,
    }
    ApiService.onFollow(data).then(() => {
      getTasks();
      window.open(currentTask.url, "_blank");
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsShowModalSinner(false);
      cancel();
    })
  }

  const onClaim = (task) => {
    setButtonSpinnerId(task.id);
    ApiService.onClaim(task.id).then(response => {
      getTasks();
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setButtonSpinnerId(null);
    })
  }

  const onChange = (
    event,
    cb = () => { },
    maxLength = Infinity,
  ) => {
    if (maxLength && maxLength < event.target.value.length) {
      return false;
    }
    cb(event.target.value);
  };

  return (
    <Row id='tasks'>
      <Col sm={12}>
        <h4 className='text-white'>Tasks</h4>
        <hr />
      </Col>

      <Col sm={12}>
        <div className='p-3 mb-2 info-bg rounded mb-3 text-white'>
          <span className='d-block mb-2'><span style={{ fontWeight: "600" }}>Complete Social Media Tasks</span></span>
          <span className='d-block ps-2'>Earn rewards by following us on social media and claiming your tokens.</span>
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <h5 className='text-white mb-3'>Social Media Tasks</h5>
          <Row className='m-0'>
            <Col sm={12}>
              {
                tasksList.map((item, index) => {
                  return <Row key={index} className='mb-3 py-2 border rounded'>
                    <Col md={8} className='d-flex align-items-center'>
                      <div className='d-flex align-items-center gap-2'>
                        <span className='text-white'>
                          {
                            getIcon(item)
                          }
                        </span>
                        <p className='mb-0 text-white'><span style={{ fontSize: "18px" }}>{item.name}</span> | Reward <span className='text-primary'>{item.amount} {TOKEN_NAME}</span>.</p>
                      </div>
                    </Col>
                    <Col md={4} className='d-flex align-items-center justify-content-end'>
                      {getButton(item)}
                    </Col>
                  </Row>
                })
              }
            </Col>
          </Row>
        </div>
      </Col>

      <Col sm={12}>
        <div>
          <hr />
          <h5 className='text-white'>Additional Information:</h5>
          <p className='d-block ps-2'>
            By following us on all of our social media platforms, you'll not only stay up to date with the latest news and announcements,
            but you'll also have the opportunity to maximize your rewards. The more platforms you follow and engage with, the more tokens
            you'll be able to claim, increasing your overall earnings. Don't miss out on this chance to fully benefit from our reward system
            and be part of our growing community!
          </p>
        </div>
      </Col>

      <Modal show={currentTask ? true : false} onHide={cancel} animation={true} className='text-dark' centered>
        <Form onSubmit={() => onFollow}>
          <Modal.Header closeButton>
            <Modal.Title >{currentTask?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <small className='d-block mb-2'>Please enter your {currentTask?.name} account {currentTask?.label}.</small>
            <input
              id='userName'
              placeholder={`Enter ${currentTask?.label}`}
              className='form-control'
              value={userName}
              autoFocus
              autoComplete="off"
              onChange={(event) => {
                onChange(event, setUserName, 120)
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancel}>
              Cancel
            </Button>
            {
              isShowModalSinner ?
                <Button type='button' variant="primary" disabled>
                  <span className='me-1'>Loading...</span>
                  <Spinner animation="border" size="sm" />
                </Button>
                : <Button
                  type='submit'
                  variant="primary"
                  disabled={!userName.trim().length}
                  onClick={onFollow}
                >
                  Submit
                </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </Row>
  );
}
