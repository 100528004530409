import { combineReducers, configureStore } from "@reduxjs/toolkit";
import SpinersReducer from "./reducers/SpinersReducer";
import UserReducer from "./reducers/UserReducer";
import FinanceReducer from "./reducers/FinanceReducer";
import ModalReducer from "./reducers/ModalReducer";

const rootReducer = combineReducers({
	spiners: SpinersReducer,
	user: UserReducer,
	finance: FinanceReducer,
	modal: ModalReducer,
});

const index = configureStore({
	reducer: rootReducer,
});

export default index;
