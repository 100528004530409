import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { ApiService } from '../../helpers/services/apiSrevice';
import AlertService from '../../helpers/services/alertService';
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";


export default function ChangePasswordForm() {
    const [values, setValues] = useState({
        oldPassword: '',
        newPassword: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
    
        if (!values.oldPassword.trim().length || !values.newPassword.trim().length) {
            setIsInvalidSubmit(true);
            AlertService.alert("warning", "Both old and new passwords are required.");
            return;
        }
    
        setIsLoading(true);
    
        const data = {
            oldPassword: values.oldPassword,
            password: btoa(values.newPassword),
            confirmPassword: btoa(values.newPassword),
        };
    
        ApiService.updateUserPassword(data)
            .then(response => {
                AlertService.alert("success", "Your password has been successfully changed.");
                setValues({
                    oldPassword: '',
                    newPassword: '',
                })
            })
            .catch(error => {
                AlertService.alert("warning", error.message || "Failed to change password. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col sm={12} md={6} className='mt-2'>
                    <Form.Group controlId="formOldPassword">
                        <Form.Label className='text-white'>Old Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter your old password"
                            value={values.oldPassword}
                            onChange={(e) => setValues({ ...values, oldPassword: e.target.value })}
                            className={isInvalidSubmit && !values.oldPassword.trim().length ? "error-border" : ""}
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} className='mt-2'>
                    <Form.Group controlId="formNewPassword" className='position-relative'>
                        <Form.Label className='text-white'>New Password</Form.Label>
                        <Form.Control
                            type={isShowPassword ? "text" : "password"}
                            placeholder="Enter your new password"
                            value={values.newPassword}
                            onChange={(e) => setValues({ ...values, newPassword: e.target.value })}
                            className={isInvalidSubmit && !values.newPassword.trim().length ? "error-border" : ""}
                        />
                        <div className='eye' onClick={() => setIsShowPassword(!isShowPassword)}>
                            {
                                isShowPassword ?
                                    <IoEyeOff size={20} />
                                    :
                                    <IoEye size={20} />
                            }
                        </div>
                    </Form.Group>
                </Col>

                <Col sm={12} className='mt-4'>
                    <div className='d-flex justify-content-end'>
                        {isLoading ? (
                            <Button className=' mb-3 px-4 update-button' variant="primary" type="submit">
                                <span>
                                    Loading
                                    <Spinner className='ms-2' animation="border" size='sm' />
                                </span>
                            </Button>
                        ) : (
                            <Button className='mb-3 px-4 update-button' variant="primary" type="submit" disabled={isLoading}>
                                Update
                            </Button>
                        )}
                    </div>
                </Col>
                <Col sm={12}>
                    <div>
                        <hr />
                        <h5 className='text-white'>Additional Information:</h5>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Old Password:</span> Enter your current password to authenticate the change.</p>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>New Password:</span> Enter the new password you wish to set. Use the eye icon to toggle visibility.</p>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Update Button:</span> Click to submit the form and change your password. The button will display a loading state while the request is in progress.</p>
                    </div>
                </Col>

            </Row>
        </Form>
    );
}
