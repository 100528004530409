import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { BiCopy } from 'react-icons/bi';
import AlertService from '../../helpers/services/alertService';
import { ApiService } from '../../helpers/services/apiSrevice';

export default function SocialMedia() {

    const [users, setUsers] = useState([]);

    const [isShowSpinner, setIsShowSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState(null);
    const [isDisabledFields, setIsDisabledFields] = useState(false);

    useEffect(() => {
        getUsers(1);
    }, []);

    const getUsers = (_currentPage, cb) => {
        const data = {
            pageNumber: _currentPage,
            pageSize: 30,
        }
        ApiService.getUserSocialMedia(data).then(response => {
            if (response && response.data) {
                setPagination(response.data.pagination);
                setUsers([...users, ...response.data.pagedData]);
            }
            setCurrentPage(_currentPage)
        }).catch(error => {
            AlertService.alert("warning", error);
        }).finally(() => {
            cb && cb(false);
        });
    }

    const loadMore = () => {
        setIsShowSpinner(true);
        getUsers(currentPage + 1, setIsShowSpinner);
    }

    const confirmUserSocialMedia = (socialMediaId, userId, status) => {
        if (!socialMediaId || !userId) { return false }
        AlertService.alertConfirm(
            `Are you sure ?`,
            "",
            "Yes",
            "No",
        ).then(() => {
            setIsDisabledFields(true);
            ApiService.confirmUserSocialMedia(socialMediaId, userId, status).then(() => {
                setUsers(users.filter(item => item.socialMediaId !== socialMediaId));
                AlertService.alert("success", "Data saved");
            }).catch(error => {
                AlertService.alert("warning", error);
            }).finally(() => {
                setIsDisabledFields(false);
            });
        }).catch(() => { });

    }

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            AlertService.alert("success", "Copied!");
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };

    const getLocalTime = (utcDate) => {
        const localDate = moment.utc(utcDate).local();
        const formattedLocalDate = localDate.format('ll HH:mm');
        return formattedLocalDate;
    }

    return (
        <Row id='referral'>

            <Col sm={12}>
                <h4 className='text-white'>Social Media</h4>
                <hr />
            </Col>
            {
                users && users.length ?
                    <Col sm={12}>
                        <Table striped bordered hover responsive variant="dark">
                            <thead>
                                <tr>
                                    <th className="text-secondary">#</th>
                                    <th className="text-secondary text-nowrap">E-mail</th>
                                    <th className="text-secondary text-nowrap">Social Media</th>
                                    <th className="text-secondary text-nowrap">Username</th>
                                    <th className="text-secondary text-nowrap">Clime Date</th>
                                    <th className="text-secondary text-nowrap">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((item, index) => {
                                        return <tr key={index}>
                                            <td className="text-secondary">{index + 1}</td>
                                            <td className="text-secondary">{item.userEmail}</td>
                                            <td className="text-secondary">{item.socialMediaName}</td>
                                            <td className="text-secondary" style={{ maxWidth: "300px" }}>
                                                {item.socialMediaUsername}
                                                <BiCopy
                                                    className="ms-2 cursor-pointer"
                                                    onClick={() => copyToClipboard(item.socialMediaUsername)}
                                                />
                                            </td>
                                            <td className="text-secondary">{getLocalTime(item.climeDate)}</td>
                                            <td className="text-secondary">
                                                <div className='d-flex gap-2'>


                                                    <Button
                                                        size='sm'
                                                        variant='success'
                                                        disabled={isDisabledFields}
                                                        onClick={() => confirmUserSocialMedia(item.socialMediaId, item.userId, 3)}
                                                    // 3 - Confirm
                                                    // 0 - Reject
                                                    >
                                                        Confirm
                                                    </Button>

                                                    <Button
                                                        size='sm'
                                                        variant='danger'
                                                        disabled={isDisabledFields}
                                                        onClick={() => confirmUserSocialMedia(item.socialMediaId, item.userId, 0)}
                                                    // 3 - Confirm
                                                    // 0 - Reject
                                                    >
                                                        Reject
                                                    </Button>

                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        {
                            pagination ?
                                pagination.currentPage === pagination.totalPages ? null :
                                    <div className="text-center">
                                        <Button variant="primary" className='w-100 my-3' size='sm' onClick={loadMore} disabled={isShowSpinner}>
                                            {
                                                isShowSpinner ? "Loading..." : "More"
                                            }
                                        </Button>
                                    </div>
                                : null
                        }
                    </Col>
                    : <Col sm={12} className='text-center'>
                        <h4>
                            No Users Yet
                        </h4>
                    </Col>
            }
        </Row>
    );
}