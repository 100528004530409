import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row, } from 'react-bootstrap'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import logo from "./../assets/zelara_images/logo-1.png"
import Finance from './components/Finance';
import Settings from './components/Settings';
import TransactionHistory from './components/TransactionHistory';
import { useSelector } from 'react-redux';
import Referral from './components/Referral';
import { TOKEN_NAME } from '../helpers/constants/constants';
import MainService from '../helpers/services/mainService';
import useCountdown from "../helpers/countdownTimer/CountdownTimer";
import roundImage from "./../assets/zelara_images/round_3.png";
import Contact from './components/Contact';
import useWindowSize from '../components/hooks/useWindowSize';
import Tasks from './components/Tasks';

export default function Dashboard() {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { rounds } = useSelector(state => state.finance)
  const { user } = useSelector(state => state.user);
  const [activeRound, setActiveRound] = useState(null);
  const { days, hours, minutes, seconds } = useCountdown(activeRound?.endDate);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize?.width > 991) {
      scrollTop();
    }
  }, [pathname]);

  const scrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 300);
  }

  useEffect(() => {
    if (rounds && rounds.length) {
      setActiveRound(rounds.find(item => item.isActive))
    }
  }, [rounds])

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user])

  return (
    <div className="container-fluid hero-header py-0 mb-5">
      <div className="container py-5 pt-3">
        <Row className="align-items-stretch">
          {/* <Col sm={12} className='mb-3'>
            <Card className='bg-primary text-black fw-600 text-center px-0 px-lg-3'>
              <CardBody>
                <span>
                  Dear users,
                  We announce the conclusion of the referral contest. The list of winners will be published on November 11, 2024. Rewards will be paid out by November 13, 2024.
                  Congratulations to the winners!
                </span>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg={4} className="mb-3 mb-lg-0">
            <Card className='h-100'>
              <CardBody>
                <div className='d-flex justify-content-center'>
                  <img src={logo} className='profile-image' alt='logo' width={180} />
                </div>
                {
                  user && user.fullName && user.fullName.trim().length ?
                    <p className='text-center mt-3 mb-1' style={{ fontWeight: "600", fontSize: "20px" }}>{user?.fullName}</p>
                    : null
                }
                <p className='text-center mt-2'>{user?.email}</p>
                <hr />
                <div className='profile-nav'>
                  <ul>
                    <li className='mb-1'><NavLink to='/dashboard/tasks' className={"rounded"}>Tasks</NavLink></li>
                    <li className='mb-1'><NavLink to='/dashboard/finance' className={"rounded"}>Finance</NavLink></li>
                    <li className='mb-1'><NavLink to='/dashboard/transaction-history' className={"rounded"}>Transaction history</NavLink></li>
                    <li className='mb-1'><NavLink to='/dashboard/referral' className={"rounded"}>Referral</NavLink></li>
                    <li className='mb-1'><NavLink to='/dashboard/contact' className={"rounded"}>Contact Information</NavLink></li>
                    <li className='mb-1'><NavLink to='/dashboard/settings' className={"rounded"}>Settings</NavLink></li>
                  </ul>
                </div>
                <hr />
                <div><Link to="/logout" className='w-100 btn btn-danger'>Logout</Link></div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={8} className="mb-3 mb-lg-0">

            {
              activeRound && rounds && rounds.length ?
                rounds.map((item, index) => {
                  if (item.isActive) {
                    return <Card className='mb-3' key={index}>
                      <CardBody>
                        <div className='d-flex service-item'>
                          <div className='position-relative'>

                            <h3 className='d-flex gap-3'>
                              <span className="text-primary">{index + 1}/{rounds.length}</span>
                              <span>ROUND</span>
                            </h3>
                            <p className="text-white" style={{ fontSize: "18px" }}>
                              1 {TOKEN_NAME} = {item.rate} USDT
                            </p>
                            <div className="mb-2">
                              <span className="d-block text-nowrap">Available Tokens: <span className="text-primary">{MainService.formatCurrency(activeRound.availableCoinsAmount, 2)}</span></span>
                            </div>
                            <div className="mb-2 mt-3 d-flex gap-4 text-center">
                              <div>
                                <p className="text-white mb-0" style={{ fontSize: "18px" }}>
                                  {days}
                                </p>
                                <span>Days</span>
                              </div>
                              <div>
                                <p className="text-white mb-0" style={{ fontSize: "18px" }}>
                                  {hours}
                                </p>
                                <span>Hours</span>
                              </div>
                              <div>
                                <p className="text-white mb-0" style={{ fontSize: "18px" }}>
                                  {minutes}
                                </p>
                                <span>Minutes</span>
                              </div>
                              <div>
                                <p className="text-white mb-0" style={{ fontSize: "18px" }}>
                                  {seconds}
                                </p>
                                <span>Seconds</span>
                              </div>
                            </div>
                          </div>
                          <div className='ms-3 w-100 round-bg-image d-md-block d-none' style={{ backgroundImage: `url(${roundImage})` }} />

                        </div>
                      </CardBody>
                    </Card>
                  }
                })
                : null
            }
            <Card className=''>
              <CardBody>
                {
                  pathname.includes("/finance") ?
                    <Finance /> :
                    pathname.includes("/transaction-history") ?
                      <TransactionHistory /> :
                      pathname.includes("/referral") ?
                        <Referral /> :
                        pathname.includes("/tasks") ?
                          <Tasks /> :
                          pathname.includes("/contact") ?
                            <Contact /> :
                            pathname.includes("/settings") ?
                              <Settings /> :
                              null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
