import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import AlertService from '../../helpers/services/alertService';
import { ApiService } from '../../helpers/services/apiSrevice';
import { TOKEN_NAME } from '../../helpers/constants/constants';
import moment from 'moment';

export default function Users() {

    const [users, setUsers] = useState([]);
    const [isShowSpinner, setIsShowSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState(null);
    const [usersCount, setUsersCount] = useState(null);
    const [usersFilter, setUsersFilter] = useState([]);
    const [isDisabeldFields, setIsDisabeldFields] = useState(false);
    const [filter, setFilter] = useState({
        filterType: null,
        dateTime: "",
        search: "",
    });

    console.log(usersFilter);

    useEffect(() => {
        getUsers(1);
    }, []);

    useEffect(() => {
        getUsersCount();
        getUsersFilter();
    }, []);

    const getUsersFilter = () => {
        ApiService.getUsersFilter().then(response => {
            if (response && response.data) {
                setUsersFilter(response.data);
            }
        }).catch(error => {
            AlertService.alert("warning", error);
        });
    }

    const getUsersCount = () => {
        ApiService.getUsersCount().then(response => {
            if (response && response.data) {
                setUsersCount(response.data);
            }
        }).catch(error => {
            AlertService.alert("warning", error);
        });
    }

    const getUsers = (_currentPage, cb, bool, filterData = null) => {
        const updatedFilter = { ...filterData || filter };
        if (!updatedFilter.dateTime) {
            delete updatedFilter.dateTime;
        }
        if (!updatedFilter.search.trim().length) {
            delete updatedFilter.search;
        }
        if (typeof updatedFilter.filterType !== "number") {
            delete updatedFilter.filterType;
        }
        const data = {
            pageNumber: _currentPage,
            pageSize: 30,
            filter: updatedFilter,
        }
        setIsDisabeldFields(true)
        ApiService.getUsersAsAdmin(data).then(response => {
            if (response && response.data) {
                setPagination(response.data.pagination);
                if (bool) {
                    setUsers(response.data.pagedData);
                } else {
                    setUsers([...users, ...response.data.pagedData]);
                }
            }
            setCurrentPage(_currentPage)
        }).catch(error => {
            AlertService.alert("warning", error);
        }).finally(() => {
            cb && cb(false);
            setIsDisabeldFields(false)
        });
    }

    const loadMore = () => {
        setIsShowSpinner(true);
        getUsers(currentPage + 1, setIsShowSpinner);
    }

    const onSelectOptionChange = (event) => {
        setFilter(prev => ({
            ...prev,
            filterType: +event.target.value
        }))
    }

    const clearFilter = () => {
        setFilter({
            dateTime: "",
            filterType: null,
            search: "",
        });
        setUsers([]);
        getUsers(1, null, true, { dateTime: "", filterType: null, search: "", });
    }

    return (
        <Row id='referral'>

            <Col sm={12}>
                <h4 className='text-white'>Users</h4>
                <hr />
            </Col>
            {
                usersCount ?
                    <Col sm={12} className='text-white'>
                        <div className='d-flex gap-3'>
                            <p className='mb-0'>Active: <b>{usersCount.activeUsersCount}</b></p>
                            <p className='mb-0'>Total: <b>{usersCount.totalUsersCount}</b></p>
                        </div>
                        <hr />
                    </Col>
                    : null
            }
            <Col sm={12}>
                <Row>

                    <Col md={4}>
                        <input
                            id='search'
                            type='text'
                            placeholder='Search'
                            className='form-control w-100 mb-2'
                            value={filter.search}
                            onChange={(e) => setFilter(prev => ({ ...prev, search: e.target.value }))}
                        />
                    </Col>
                    <Col md={4}>
                        {
                            usersFilter && usersFilter.length ?
                                <Form.Select
                                    aria-label="Filter by"
                                    className='mb-2 mb-md-0'
                                    onChange={onSelectOptionChange}
                                    value={filter.filterType || ""}
                                >
                                    {typeof filter.filterType !== "number" ? (
                                        <option value="">Filter by</option>
                                    ) : null}
                                    {usersFilter.map((item, index) => (
                                        <option key={index} value={item.key}>{item.value}</option>
                                    ))}
                                </Form.Select>
                                : null
                        }
                    </Col>
                    <Col md={4}>
                        <input
                            id='dateTime'
                            type='date'
                            className='form-control'
                            value={filter.dateTime}
                            onChange={(e) => setFilter(prev => ({ ...prev, dateTime: e.target.value }))}
                        />
                    </Col>
                    <Col sm={12} className='mt-2'>
                        <div className='d-flex gap-2 justify-content-end'>

                            {
                                filter.dateTime || typeof filter.filterType === "number" || filter.search.trim().length ?
                                    <Button
                                        variant="danger"
                                        className='text-nowrap'
                                        disabled={isDisabeldFields}
                                        onClick={clearFilter}
                                    >
                                        Clear Filter
                                    </Button>
                                    : null
                            }
                            <Button
                                variant="success"
                                className='px-4'
                                onClick={() => getUsers(1, null, true)}
                                disabled={(!filter.dateTime && typeof filter.filterType !== "number" && !filter.search.trim().length) || isDisabeldFields}
                            >
                                Filter
                            </Button>
                        </div>
                    </Col>
                </Row>

                <hr />
            </Col>
            {
                users && users.length ?
                    <Col sm={12}>
                        <Table striped bordered hover responsive variant="dark">
                            <thead>
                                <tr>
                                    <th className="text-secondary">#</th>
                                    <th className="text-secondary text-nowrap">E-mail</th>
                                    <th className="text-secondary text-nowrap">Active Ref-s</th>
                                    <th className="text-secondary text-nowrap">Inactive Ref-s</th>
                                    <th className="text-secondary text-nowrap">Main Balance</th>
                                    <th className="text-secondary text-nowrap">Staking Balance</th>
                                    <th className="text-secondary text-nowrap">Create Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((item, index) => {
                                        return <tr key={index}>
                                            <td className="text-secondary">{index + 1}</td>
                                            <td className="text-secondary">{item.userEmail}</td>
                                            <td className="text-secondary">{item.activeReferralsCount}</td>
                                            <td className="text-secondary">{item.inactiveReferralsCount}</td>
                                            <td className="text-secondary">{item.mainBalance} {TOKEN_NAME}</td>
                                            <td className="text-secondary">{item.stakingBalance} {TOKEN_NAME}</td>
                                            <td className="text-secondary">{moment(item.createDate).format("ll")}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        {
                            pagination ?
                                pagination.currentPage === pagination.totalPages ? null :
                                    <div className="text-center">
                                        <Button variant="primary" className='w-100 my-3' size='sm' onClick={loadMore} disabled={isShowSpinner}>
                                            {
                                                isShowSpinner ? "Loading..." : "More"
                                            }
                                        </Button>
                                    </div>
                                : null
                        }
                    </Col>
                    : <Col sm={12} className='text-center'>
                        <h4>
                            No Users Yet
                        </h4>
                    </Col>
            }
        </Row>
    );
}
