import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowSize from "../hooks/useWindowSize";
import GoogleLoginButton from "../../pages/components/GoogleLoginButton";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../../pages/components/ScrollToTop";
import DepositModal from "../../pages/components/DepositModal";
import WelcomeAnimation from "../welcomeAnimation/WelcomeAnimation";


function Layaut(props) {

	const { user } = useSelector(state => state.user);

	return (
		<div>
			<div className="d-none">
				{
					!user ? <GoogleLoginButton /> : null
				}
			</div>
			{/* <ScrollToTop> */}
				<WelcomeAnimation />
				<Header />
				<Outlet />
				<Footer />
			{/* </ScrollToTop> */}
			<DepositModal />
		</div>
	);
}

export default Layaut;