// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { useAccount } from "wagmi";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
// import DepositModal from "./DepositModal";
// import NeonButton from "../../components/buttons/NeonButton";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// const Deposit = ({ buttonType = 1, buttonTitle = "Deposit Now", size = "", buttonClassName = "", blockClassName = "", buttonVariant = "primary" }) => {

// 	const navigate = useNavigate();
// 	const { user } = useSelector(state => state.user);
// 	const { address, isConnected } = useAccount();

// 	const [isShowDepositModal, setIsShowDepositModal] = useState(false);

// 	useEffect(() => {
// 		setIsShowDepositModal(true)
// 	}, [isConnected])

// 	const { open, close } = useWeb3Modal();

// 	const handleClose = () => {
// 		setIsShowDepositModal(false);
// 	};

// 	return (
// 		<div className={blockClassName}>
// 			{
// 				buttonType === 1 ?
// 					<NeonButton
// 						buttonClassName={buttonClassName}
// 						onClick={() => {
// 							if (user) {
// 								if (!address) {
// 									open();
// 								}
// 								setIsShowDepositModal(true);
// 							} else {
// 								navigate("/login")
// 							}

// 						}}
// 						title={buttonTitle}
// 					/>
// 					:
// 					<Button
// 						size={size}
// 						className={buttonClassName}
// 						variant={buttonVariant}
// 						onClick={() => {
// 							if (user) {
// 								if (!address) {
// 									open();
// 								}
// 								setIsShowDepositModal(true);
// 							} else {
// 								navigate("/login")
// 							}

// 						}}
// 					>
// 						{buttonTitle}
// 					</Button>
// 			}
// 			{isConnected ? (
// 				<DepositModal
// 					cancel={handleClose}
// 					isShowDepositModal={isShowDepositModal}
// 					setIsShowDepositModal={setIsShowDepositModal}
// 				/>
// 			) : null}
// 		</div>
// 	);
// };

// export default Deposit;




import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import DepositModal from "./DepositModal";
import NeonButton from "../../components/buttons/NeonButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openDepositModal } from "../../store/reducers/ModalReducer";

const Deposit = ({ buttonType = 1, buttonTitle = "Deposit Now", size = "", buttonClassName = "", blockClassName = "", buttonVariant = "primary" }) => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.user);
	const { address, isConnected } = useAccount();

	// useEffect(() => {
	// 	if (isConnected) {
	// 		_openDepositModal();
	// 	}
	// }, [isConnected]);

	const _openDepositModal = () => {
		dispatch(openDepositModal());
	}

	const { open } = useWeb3Modal();

	return (
		<div className={blockClassName}>
			{
				buttonType === 1 ? (
					<NeonButton
						buttonClassName={buttonClassName}
						onClick={() => {
							if (user) {
								if (!address) {
									open();
								} else {
									_openDepositModal()
								}
							} else {
								navigate("/login");
							}
						}}
						title={buttonTitle}
					/>
				) : (
					<Button
						size={size}
						className={buttonClassName}
						variant={buttonVariant}
						onClick={() => {
							if (user) {
								if (!address) {
									open();
								} else {
									_openDepositModal()
								}
							} else {
								navigate("/login");
							}
						}}
					>
						{buttonTitle}
					</Button>
				)
			}
		</div>
	);
};

export default Deposit;
