import axios from "axios";

const IDENTITY_API_URL_KEY = process.env.REACT_APP_IDENTITY_API_URL_KEY;

const identityApi = axios.create({
	baseURL: IDENTITY_API_URL_KEY,
});

class IdentityService {

	static userLogin(data) {
		return identityApi.post(`/identity/GetPWDAccessToken`, data);
	}

}

export default IdentityService;
