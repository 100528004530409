import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Layaut from "../components/layaut/Layaut";
import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import RestorePassword from "./RestorePassword";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Page_404 from "./Page_404";
import Contact from "./Contact";
import Dashboard from "./Dashboard";
import Logout from "./Logout";
import AdminDashboard from "./AdminDashboard";

const router = type => {
	return createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layaut />}>

				<Route
					index
					element={<Home />}
				/>
				<Route
					path="about"
					element={<About />}
				/>
				<Route
					path="contact"
					element={<Contact />}
				/>
				<Route
					path="login"
					element={<Login />}
				/>
				<Route
					path="logout"
					element={<Logout />}
				/>
				<Route
					path="forgot-password"
					element={<ForgotPassword />}
				/>
				<Route
					path="restore-password"
					element={<RestorePassword />}
				/>
				<Route
					path="registration"
					element={<Registration />}
				/>
				<Route
					path="terms-and-conditions"
					element={<TermsAndConditions />}
				/>
				<Route
					path="privacy-policy"
					element={<PrivacyPolicy />}
				/>
				<Route
					path="dashboard/finance"
					element={<Dashboard />}
				/>
				<Route
					path="dashboard/settings"
					element={<Dashboard />}
				/>
				<Route
					path="dashboard/transaction-history"
					element={<Dashboard />}
				/>
				<Route
					path="dashboard/referral"
					element={<Dashboard />}
				/>
				<Route
					path="dashboard/tasks"
					element={<Dashboard />}
				/>
				<Route
					path="dashboard/contact"
					element={<Dashboard />}
				/>
				<Route
					path="admin/users"
					element={<AdminDashboard />}
				/>
				<Route
					path="admin/social-media"
					element={<AdminDashboard />}
				/>
				<Route
					path="*"
					element={<Page_404 />}
				/>
			</Route>
		),
	);
};

export { router };
