// import React, { useEffect, useState } from 'react'
// import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
// import { useDispatch, useSelector } from 'react-redux'
// import { ApiService } from './../../helpers/services/apiSrevice';
// import AlertService from '../../helpers/services/alertService';
// import { updateUserData } from '../../store/reducers/UserReducer';

// export default function AccountForm() {

//     const { user } = useSelector(state => state.user);
//     const dispatch = useDispatch();
//     const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);

//     const [values, setValues] = useState({
//         firstName: "",
//         lastName: "",
//         email: ""
//     });

//     useEffect(() => {
//         if (user) {
//             setValues({
//                 firstName: user.firstName || "",
//                 lastName: user.lastName || "",
//                 email: user.email || ""
//             });
//         }
//     }, [user]);

//     const onSubmit = (event) => {
//         event.preventDefault();

//         if (!values.email.trim().length) {
//             setIsInvalidSubmit(true);
//             return;
//         }

//         setIsLoading(true);

//         ApiService.updateUserData(values).then(response => {
//             const updatedUser = {
//                 ...user,
//                 ...values,
//             };
//             localStorage.setItem("user", JSON.stringify(updatedUser))
//             dispatch(updateUserData(updatedUser));
//             AlertService.alert("success", "User data updated successfully");
//         }).catch(error => {
//             AlertService.alert("warning", error.message || "Failed to update user data");
//         }).finally(() => {
//             setIsLoading(false);
//         });
//     }

//     return (
//         <Form onSubmit={onSubmit}>
//             <Row>
//                 <Col sm={12} md={6} className='mt-2'>
//                     <Form.Group controlId="formFirstName">
//                         <Form.Label className='text-white'>First Name</Form.Label>
//                         <Form.Control
//                             type="text"
//                             placeholder="Enter your first name"
//                             value={values.firstName}
//                             onChange={(e) => setValues({ ...values, firstName: e.target.value })}
//                         />
//                     </Form.Group>
//                 </Col>

//                 <Col sm={12} md={6} className='mt-2'>
//                     <Form.Group controlId="formLastName">
//                         <Form.Label className='text-white'>Last Name</Form.Label>
//                         <Form.Control
//                             type="text"
//                             placeholder="Enter your last name"
//                             value={values.lastName}
//                             onChange={(e) => setValues({ ...values, lastName: e.target.value })}
//                         />
//                     </Form.Group>
//                 </Col>

//                 <Col sm={12} className='mt-2'>
//                     <Form.Group controlId="formEmail">
//                         <Form.Label className='text-white'>E-mail</Form.Label>
//                         <Form.Control
//                             type="email"
//                             placeholder="Enter e-mail"
//                             value={values.email}
//                             required
//                             className={isInvalidSubmit && !values.email.trim().length ? "error-border" : ""}
//                             onChange={(e) => setValues({ ...values, email: e.target.value })}
//                         />
//                     </Form.Group>
//                 </Col>

//                 <Col sm={12} className='mt-4'>
//                     <div className='d-flex justify-content-end'>
//                         {
//                             isLoading ?
//                                 <Button className='mb-3 px-4 update-button' variant="primary">
//                                     <span>
//                                         Loading
//                                         <Spinner className='ms-2' animation="border" size='sm' />
//                                     </span>
//                                 </Button>
//                                 : <Button className='mb-3 px-4 update-button' variant="primary" type="submit" disabled={isLoading}>
//                                     Update
//                                 </Button>
//                         }
//                     </div>
//                 </Col>

//                 <Col sm={12}>
//                     <div>
//                         <hr />
//                         <h6 className='text-white'>Additional Information:</h6>
//                         <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>First Name:</span> Enter or update your first name.</p>
//                         <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Last Name:</span> Enter or update your last name.</p>
//                         <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>E-mail:</span> Enter a valid e-mail address. This field is required.</p>
//                         <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Update Button:</span> Click to save changes. The button will show a loading state while the update is in progress.</p>
//                     </div>
//                 </Col>



//             </Row>
//         </Form >
//     )
// }





import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ApiService } from './../../helpers/services/apiSrevice';
import AlertService from '../../helpers/services/alertService';
import { updateUserData } from '../../store/reducers/UserReducer';

export default function AccountForm() {

    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: ""
    });

    useEffect(() => {
        if (user) {
            setValues({
                firstName: user.firstName || user.firstname || "",
                lastName: user.lastName || user.lastname || "",
                email: user.email || ""
            });
        }
    }, [user]);

    const onSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
        }

        ApiService.updateUserData(data)
            .then(() => {
                const updatedUser = {
                    ...user,
                    firstName: values.firstName,
                    lastName: values.lastName,
                };
                localStorage.setItem("user", JSON.stringify(updatedUser));
                dispatch(updateUserData(updatedUser));
                AlertService.alert("success", "User data has been successfully updated.");
            })
            .catch(error => {
                AlertService.alert("warning", error.message || "An error occurred while updating user data. Please try again.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col sm={12} md={6} className='mt-2'>
                    <Form.Group controlId="formFirstName">
                        <Form.Label className='text-white'>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your first name"
                            value={values.firstName}
                            onChange={(e) => setValues({ ...values, firstName: e.target.value })}
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} className='mt-2'>
                    <Form.Group controlId="formLastName">
                        <Form.Label className='text-white'>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your last name"
                            value={values.lastName}
                            onChange={(e) => setValues({ ...values, lastName: e.target.value })}
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} className='mt-2'>
                    <Form.Group controlId="formEmail">
                        <Form.Label className='text-white'>E-mail</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter e-mail"
                            value={values.email}
                            readOnly
                            className={isInvalidSubmit && !values.email.trim().length ? "error-border" : ""}
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} className='mt-4'>
                    <div className='d-flex justify-content-end'>
                        {
                            isLoading ?
                                <Button className='mb-3 px-4 update-button' variant="primary">
                                    <span>
                                        Loading
                                        <Spinner className='ms-2' animation="border" size='sm' />
                                    </span>
                                </Button>
                                : <Button className='mb-3 px-4 update-button' variant="primary" type="submit" disabled={isLoading}>
                                    Update
                                </Button>
                        }
                    </div>
                </Col>

                <Col sm={12}>
                    <div>
                        <hr />
                        <h5 className='text-white'>Additional Information:</h5>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>First Name:</span> Enter or update your first name.</p>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Last Name:</span> Enter or update your last name.</p>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>E-mail:</span> This field is read-only and cannot be changed.</p>
                        <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Update Button:</span> Click to save changes. The button will show a loading state while the update is in progress.</p>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}
