import React from 'react'

export default function Contact() {
  return (
    <div className='container-fluid hero-header py-5 mb-5'>
      <h3 className='text-center my-3'>
        Contact Us
      </h3>
    </div>
  )
}
