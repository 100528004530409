import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IdentityService from '../helpers/services/identityService';
import AlertService from '../helpers/services/alertService';
import { useDispatch, useSelector } from 'react-redux';
import { ApiService } from '../helpers/services/apiSrevice';
import { setToken, setUserData } from '../store/reducers/UserReducer';
import GoogleLoginButton from './components/GoogleLoginButton';
import logo_3 from "./../assets/unauth_pages_images/ai_3.jpg"


export default function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, token } = useSelector(state => state.user);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    rememberMe: false,
  })

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user])

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.value
    }))
    setIsInvalidSubmit(false);
  }

  const onCheckboxChange = (event, fieldName) => {
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.checked
    }))
  }
  
  const getCurrentUser = async () => {
    try {
      const userResponse = await ApiService.getCurrentUser();
      localStorage.setItem('user', JSON.stringify(userResponse.data));
      dispatch(setUserData(userResponse.data));
      localStorage.removeItem("referral");
    } catch (error) {
      AlertService.alert("warning", error);
      localStorage.removeItem("token");
      dispatch(setToken(null));
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = async (event) => {
    event && event.preventDefault();

    if (!values.email.trim().length || !values.password.trim().length || isLoading) {
      setIsInvalidSubmit(true);
      return false;
    }
    setIsLoading(true);
    let data = {
      username: values.email,
      password: values.password
    };
    try {
      const response = await IdentityService.userLogin(data);
      if (response && response.data) {
        const token = response.data.accessToken;
        if (token) {
          localStorage.setItem('token', token);
          dispatch(setToken(token));
          setTimeout(() => {
            getCurrentUser();
          }, 100);
        } else {
          throw new Error('Invalid E-mail or Password.');
        }
      }
    } catch (error) {
      AlertService.alert("warning", error.message);
      setIsLoading(false);
    }
  };


  return (
    <div>

      <div className="container-fluid hero-header py-lg-5 py-0 mb-5 unauth-container">
        <div className="container py-5">
          <div className="row g-5 align-items-center">

              <Card className='p-0'>
                <CardBody className='p-0'>
                <div className='d-flex'>
                    <div className="w-100 content-block">
                      <h1 className="display-4 mb-3">Sign In</h1>
                      <div>
                        <Form onSubmit={onSubmit}>

                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter e-mail"
                              className={isInvalidSubmit && !values.email.trim().length ? "error-border" : ""}
                              value={values.email}
                              onChange={(event) => onChange(event, "email")}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter password"
                              value={values.password}
                              className={isInvalidSubmit && !values.password.trim().length ? "error-border" : ""}
                              onChange={(event) => onChange(event, "password")}
                            />
                          </Form.Group>

                          <div className='d-flex justify-content-between'>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                              <Form.Check
                                type="checkbox"
                                label="Remember me"
                                value={values.rememberMe}
                                onChange={(event) => onCheckboxChange(event, "rememberMe")}
                              />
                            </Form.Group>

                            <Link to={"/forgot-password"} className='text-primary'>Forgot password ?</Link>
                          </div>
                          {
                            isLoading ?
                              <Button className='w-100 mb-3 px-4' variant="primary" type="submit">
                                <span>
                                  Loading
                                  <Spinner className='ms-2' animation="border" size='sm' />
                                </span>
                              </Button>
                              : <Button className='w-100 mb-3' variant="primary" type="submit" disabled={isLoading}>
                                Sign In
                              </Button>
                          }
                        </Form>
                        <div>
                          <div className='my-2 d-flex align-items-center justify-content-between gap-2'>
                            <hr className='w-100' />
                            <span className='text-primary'>or</span>
                            <hr className='w-100' />
                          </div>

                          <div className={`mb-4 mt-1 d-flex justify-content-center ${isLoading ? "disabled" : ""}`}>
                            <GoogleLoginButton />
                          </div>
                        </div>

                        <div className='text-center'>
                          Not registred yet ? <Link to={"/registration"} className='text-primary'>Create an account</Link>
                        </div>
                      </div>
                    </div>

                    <div className="w-100 d-none d-lg-block image-block">
                      <div
                        className='bg-image'
                        style={{
                          backgroundImage: `url(${logo_3})`
                        }}>
                      </div>
                    </div>
                    </div>
                </CardBody>
              </Card>


          </div>
        </div>
      </div>

    </div>
  )
}
