import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { GoMail } from 'react-icons/go';
import { RiFacebookCircleLine, RiInstagramLine, RiTwitterXLine, RiTelegramLine } from "react-icons/ri";
import useWindowSize from '../../components/hooks/useWindowSize';
import useScrollToElement from '../../components/hooks/useScrollToElement';

export default function Contact() {

  const windowSize = useWindowSize();
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (windowSize?.width <= 991) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [windowSize]);

  useScrollToElement("contact", shouldScroll, 180);

  return (
    <Row id='contact'>
      <Col sm={12}>
        <h4 className='text-white'>Contact Information</h4>
        <hr />
      </Col>

      <Col sm={12}>
        <div className='p-3 mb-2 info-bg rounded mb-3 text-white'>
          <span className='d-block mb-2'>
            <span style={{ fontWeight: "600" }}>Get in Touch</span>
          </span>
          <span className='d-block ps-2'>•&nbsp;
            Have questions or need assistance? Reach out to us via the emails below. We’re here to help and ensure you have the best experience.
          </span>
        </div>
      </Col>

      <Col sm={12}>
        <Row>
          <Col md={6} className='mt-2 mt-md-0'>
            <h5 className='text-white'>Customer Support</h5>
            <Card className='border'>
              <Card.Body>
                <div className='d-flex align-items-end flex-nowrap'>
                  <GoMail size={20} className="text-white me-2" />
                  <a className="text-primary text-center" href="mailto:customers@zelara.org">customers@zelara.org</a>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className='mt-2 mt-md-0'>
            <h5 className='text-white'>Business Inquiries</h5>
            <Card className='border'>
              <Card.Body>
                <div className='d-flex align-items-end flex-nowrap'>
                  <GoMail size={20} className="text-white me-2" />
                  <a className="text-primary text-center" href="mailto:business@zelara.org">business@zelara.org</a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>

      <Col sm={12}>
        <div className='mt-4'>
          <h5 className='text-white'>Connect with Us</h5>
          <div>
            <div className='mb-2'>
              <span>Follow us on social media to stay updated:</span>
            </div>
            <div className='d-block border p-3 rounded my-3 text-white'>
              <div className='d-flex justify-content-around flex-wrap'>
                <div className='text-center d-inline-block my-1'>
                  <p className='text-white mb-1'><RiFacebookCircleLine size={30} /></p>
                  <a className='text-primary' href='https://www.facebook.com/profile.php?id=61566940469633' target='_blank' rel='noopener noreferrer'>Facebook</a>
                </div>
                <div className='text-center d-inline-block my-1'>
                  <p className='text-white mb-1'><RiInstagramLine size={30} /></p>
                  <a className='text-primary' href='https://www.instagram.com/zelaratoken' target='_blank' rel='noopener noreferrer'>Instagram</a>
                </div>
                <div className='text-center d-inline-block my-1'>
                  <p className='text-white mb-1'><RiTwitterXLine size={30} /></p>
                  <a className='text-primary' href='https://x.com/ZelaraToken' target='_blank' rel='noopener noreferrer'>X (Twitter)</a>
                </div>
                <div className='text-center d-inline-block my-1'>
                  <p className='text-white mb-1'><RiTelegramLine size={30} /></p>
                  <a className='text-primary' href='https://t.me/zelaratoken' target='_blank' rel='noopener noreferrer'>Telegram</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Col sm={12}>
        <div>
          <hr />
          <h5 className='text-white'>Additional Information</h5>
          <p className='d-block'>
            Our team is always ready to assist you. Whether it's a question about our platform, a support request, or a business opportunity, we’ll get back to you as quickly as possible. Your satisfaction is our priority.
          </p>
        </div>
      </Col>
    </Row>
  );
}
