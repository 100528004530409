import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useAccount, useWriteContract } from 'wagmi';
import AlertService from '../../helpers/services/alertService';
import { useDispatch, useSelector } from 'react-redux';
import { ApiService } from '../../helpers/services/apiSrevice';
import { HELPER_DATA, TOKENS, TOKEN_NAME } from '../../helpers/constants/constants';
import MainService from '../../helpers/services/mainService';
import { parseUnits } from 'viem';
import { closeDepositModal } from '../../store/reducers/ModalReducer';
import { chains, config } from '../../WagmiComponent';
import { switchChain } from '@wagmi/core';
import chain_1 from "./../../assets/chains/chain_1.png"
import chain_2 from "./../../assets/chains/chain_2.png"
import chain_3 from "./../../assets/chains/chain_3.png"
import chain_4 from "./../../assets/chains/chain_4.png"
import chain_5 from "./../../assets/chains/chain_5.png"
import chain_6 from "./../../assets/chains/chain_6.png"
import chain_7 from "./../../assets/chains/chain_7.png"

export default function DepositModal() {

    const dispatch = useDispatch();
    const { isDepositModalOpen } = useSelector(state => state.modal)
    const { user } = useSelector(state => state.user);
    const { rate } = useSelector(state => state.finance);
    const tokens = [...TOKENS];
    const { address, chain, chainId, isConnected } = useAccount();
    const [isShowButtonSpinner, setIsShowButtonSpinner] = useState(false);
    const [amount, setAmount] = useState("");
    const { writeContractAsync } = useWriteContract();
    const [selectedToken, setSelectedToken] = useState(tokens && tokens.length ? tokens[0] : null);
    const to = process.env.REACT_APP_WALLET_ADDRESS;

    const onNumberChange = (event, cb, maxValue = Infinity) => {
        const value = event.target.value;
        const regex = /^\d*\.?\d*$/;
        if (!regex.test(value) || value.includes("e")) {
            return false;
        }
        const sanitizedValue = value.replace(/^0+(?=\d)/, '');
        if (
            sanitizedValue === "" ||
            (typeof +sanitizedValue === "number" &&
                +sanitizedValue >= 0 &&
                +sanitizedValue <= maxValue)
        ) {
            cb(sanitizedValue);
        }
    };

    const onSubmit = async (event) => {
        event && event.preventDefault();

        if (!address || !selectedToken || isShowButtonSpinner) { return false; }
        setIsShowButtonSpinner(true);

        let abi = HELPER_DATA.ABI[selectedToken.shortName][chainId];
        let contract = HELPER_DATA.CONTRACT[selectedToken.shortName][chainId];
        let decimal = HELPER_DATA.DECIMAL[chainId];
        try {
            const result = await writeContractAsync({
                chainId: chainId,
                address: contract,
                abi: abi,
                functionName: 'transfer',
                args: [to, parseUnits(amount, decimal)],
            });
            makeTransaction(result, contract);
        } catch (error) {
            setIsShowButtonSpinner(false);
            handleClose();
            getError(error);
        }
    }

    const makeTransaction = async (txHash, contract) => {
        if (!txHash || !contract) { return false; }
        const data = {
            userId: user.id,
            networkId: chainId,
            fromAddress: address,
            transactionHash: txHash,
            amount: +amount,
            contractAddress: contract,
            toAddress: to,
            // tokenId: selectedToken.id,
        };
        ApiService.makeTransaction(data).then(() => {
            AlertService.alert("success", "Transaction created successfully!");
        }).catch(error => AlertService.alert("warning", error)).finally(() => {
            setIsShowButtonSpinner(false);
            handleClose();
        })
    }

    const handleClose = () => {
        setAmount("");
        dispatch(closeDepositModal());
    }

    const getError = (err) => {
        if (!err) { return "" }
        switch (err.name) {
            case "ContractFunctionExecutionError":
                // AlertService.alert("warning", "An error occurred while executing the contract function. This may be due to incorrect parameters or insufficient funds. Please check the parameters and try again.")
                AlertService.alert("warning", "Insufficient funds")
                break;
            case "TransactionExecutionError":
                // AlertService.alert("warning", "An error occurred during the execution of the transaction. This could be caused by a network issue or a problem with the transaction itself. Please try again later.")
                AlertService.alert("warning", "Insufficient funds")
                break;
            case "UserRejectedRequestError":
                AlertService.alert("warning", "The transaction was rejected by the user. Please authorize the transaction and try again.")
                break;
            default:
                AlertService.alert("warning", err.name)
                break;
        }
    }

    const getChainLogo = (id) => {
        switch (id) {
            case 1: //Ethereum
                return <img src={`${chain_1}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />
            case 56: //BNB Smart Chain
                return <img src={`${chain_2}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />
            case 42161: //Arbitrum One
                return <img src={`${chain_3}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />
            case 43114: //Avalanche
                return <img src={`${chain_4}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />
            case 8453: //Base
                return <img src={`${chain_5}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />
            case 10: //OP Mainnet
                return <img src={`${chain_6}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />
            case 137: //Polygon
                return <img src={`${chain_7}`} alt="logo" height={20} style={{ margin: "0 10px", borderRadius: "50%" }} />

            default:
                break;
        }
    }

    const _switchChain = async (id) => {
        try {
            await switchChain(config, { chainId: id })
        } catch (error) {
            AlertService.alert("warning", `Failed to switch chain.`)
        }
    }


    return (
        <Modal show={isDepositModalOpen} onHide={handleClose} animation={true} className='text-dark' size='lg'>
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Make a Deposit</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='p-3 mb-2 info-bg  rounded mb-3'>
                        Please select a network from the list below, as well as the token you wish to use for the transaction.
                    </div>
                    <b><small>Networks</small></b>
                    <div className='d-sm-flex gap-2 mb-2 flex-wrap'>
                        {
                            chains && chains.length ?
                                chains.map((item, index) => {

                                    return <div key={index} className='border border-success rounded d-flex coin-block d-sm-0 mt-2'
                                        onClick={() => {
                                            _switchChain(item.id)
                                        }}
                                    >
                                        <div className='d-flex justify-content-center align-items-center border-success p-2' style={{ borderRight: "1px solid" }}>
                                            {getChainLogo(item.id)}
                                        </div>
                                        <Button
                                            variant={`${item?.id === chainId ? "success" : "outline-success"}`}
                                            className='ps-2 pe-3 py-1 d-flex align-items-center w-100'
                                            style={{ fontWeight: "600", borderRadius: 0, border: 0 }}
                                        >
                                            <div className='ms-1 d-flex flex-column align-items-start'>
                                                <span>{item.name}</span>
                                            </div>
                                        </Button>
                                    </div>
                                })
                                : null
                        }
                    </div>
                    <b><small>Tokens</small></b>
                    <div className='d-sm-flex gap-3 mb-2'>
                        {
                            tokens && tokens.length ?
                                tokens.map((item, index) => {

                                    return <div key={index} className='border border-success rounded d-flex coin-block d-sm-0 mt-2'
                                        onClick={() => {
                                            setSelectedToken(item)
                                        }}
                                    >
                                        <div className='d-flex justify-content-center align-items-center border-success' style={{ borderRight: "1px solid" }}>
                                            <img src={item.logo} alt="logo" height={25} style={{ margin: "0 20px" }} />
                                        </div>
                                        <Button
                                            variant={`${item?.id === selectedToken?.id ? "success" : "outline-success"}`}
                                            className='ps-2 pe-4 py-1 d-flex align-items-center w-100'
                                            style={{ fontWeight: "600", borderRadius: 0, border: 0 }}

                                        >
                                            <div className='ms-1 d-flex flex-column align-items-start'>
                                                <span>{item.shortName}</span>
                                                <small style={{ fontWeight: "400" }}>{item.fullName}</small>
                                            </div>
                                        </Button>
                                    </div>
                                })
                                : null
                        }
                    </div>
                    <div>
                        <label className='form-label' htmlFor='amount'>Please enter the amount you want to deposit.</label>
                        <input
                            id='amount'
                            placeholder={`Enter amount (${selectedToken.fullName})`}
                            className='form-control'
                            value={amount}
                            autoFocus
                            autoComplete='off'
                            disabled={!selectedToken}
                            onChange={(event) => onNumberChange(event, setAmount)}
                        />
                    </div>
                    {
                        selectedToken && amount ?
                            <div className='mt-2'>
                                <small className='fw-bold' style={{ wordBreak: "break-all" }}>
                                    {MainService.formatCurrency(amount, 2)} {selectedToken?.shortName} = {MainService.formatCurrency(Math.floor(+amount / +rate), 2)} {TOKEN_NAME}
                                </small>
                            </div>
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    {
                        isShowButtonSpinner ?
                            <Button type='button' variant="primary" disabled>
                                <span className='me-1'>Loading...</span>
                                <Spinner animation="border" size="sm" />
                            </Button>
                            : <Button
                                type='submit'
                                variant="primary"
                                disabled={+amount > 0 && selectedToken ? false : true}
                                onClick={onSubmit}
                            >
                                Deposit
                            </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
