import React from "react";
import { PROJECT_NAME } from "../../helpers/constants/constants";

function Footer(props) {

	return (
		<div
			className="section mt-3"
			style={{ borderBottomLeftRadius: "0", borderBottomRightRadius: "0" }}
		>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div>
							Copyright &copy; <span className="text-primary">{PROJECT_NAME}</span>. All Right Reserved.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;