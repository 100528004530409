import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setToken, setUserData } from '../store/reducers/UserReducer';
import { config } from './../WagmiComponent';
import { disconnect } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { googleLogout } from '@react-oauth/google';

export default function Logout() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isConnected, address, chain } = useAccount();

  useEffect(() => {
    address && _disconnect();
  }, [address])

  const _disconnect = async () => {
    await disconnect(config);
  }

  useEffect(() => {
    googleLogout();
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(setUserData(null));
    dispatch(setToken(""));
    navigate("/");
  }, [])

  return null
}
