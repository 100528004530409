import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertService from '../../helpers/services/alertService';
import { ApiService } from '../../helpers/services/apiSrevice';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { setToken, setUserData } from '../../store/reducers/UserReducer';
import { Spinner } from 'react-bootstrap';

export default function GoogleLoginButton({ referral = "" }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, token } = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user && token) {
            navigate("/");
        }
    }, [user, token])

    const login = (credentialResponse) => {
        if (credentialResponse && credentialResponse.credential) {
            setIsLoading(true);
            let jwtToken = credentialResponse.credential;
            let data = {
                referral: referral ? referral.toString() : "",
                credential: jwtToken,
            }
            ApiService.googleLogin(data).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };
                    if (data.pwd && data.pwd.tokenResponse && data.user) {
                        const _user = data.user;
                        const _token = response.data.pwd?.tokenResponse?.accessToken;
                        localStorage.setItem('token', _token);
                        dispatch(setToken(_token));
                        localStorage.setItem('user', JSON.stringify(_user));
                        dispatch(setUserData(_user));
                        localStorage.removeItem("referral");
                    }
                }
            }).catch(error => {
                AlertService.alert("warning", error)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };

    return (
        <div>
            <Button className='bg-white p-0' style={{ border: "none", borderRadius: "3px", overflow: "hidden" }} variant='secondary'>
                {
                    isLoading ? <div className='d-flex'>
                        <Spinner animation="border" size='sm' className='text-dark' style={{ margin: "12px", }} />
                    </div> :
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                login(credentialResponse);
                            }}
                            onError={(error) => {
                                AlertService.alert("warning", error)
                            }}
                            width="200"
                            shape="rectangular"
                            text="continue_with"
                            locale='en_US'
                            type='standard'
                            ux_mode='popup'
                        // useOneTap={true}
                        // auto_select={true}
                        />
                }
            </Button>
            {
                useGoogleOneTapLogin({
                    onSuccess: (credentialResponse) => {
                        login(credentialResponse);
                    },
                    onError: (error) => {
                        AlertService.alert("warning", error)
                    },
                })
            }
        </div>
    )
}
