import { createSlice } from "@reduxjs/toolkit";

const financeSlice = createSlice({
	name: "finance",
	initialState: {
		tokens: [],
		rounds: [],
		rate: 0,
	},
	reducers: {
		setTokens: (state, { payload }) => {
			state.tokens = payload;
		},
		setRounds: (state, { payload }) => {
			state.rounds = payload;
			state.rate = payload.find(item => item.isActive)?.rate;
		},
	},
});

export const {
	setTokens,
	setRounds,
} = financeSlice.actions;

export default financeSlice.reducer;
