import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import {
	ERROR_KEY,
	INFO_KEY,
	SUCCESS_KEY,
	WARNING_KEY,
} from "../constants/constants";
import Auxiliary from "../../components/hoc/Auxiliary";
import { Button, Modal } from "react-bootstrap";

const getAlertType = (type, cb, error) => {
	if (!type) {
		cb.error(error);
	}
	switch (type) {
		case "info":
			return cb.info(error);
		case "success":
			return cb.success(error);
		case "warning":
			return cb.warning(error);
		case "error":
			return cb.error(error);
		default:
			break;
	}
};

class AlertService {
	static checkMessageType = respcode => {
		switch (respcode) {
			case 0:
				return SUCCESS_KEY;
			case 1:
				return ERROR_KEY;
			case 2:
				return WARNING_KEY;
			case 3:
				return INFO_KEY;
			case 4:
				return WARNING_KEY;
			default:
				return ERROR_KEY;
		}
	};

	static alert = (type, error) => {
		const respMessage =
			typeof error === "object" ? error.message || error.respmess : error;
		if (!respMessage) {
			return false;
		}
		getAlertType(type, toast, respMessage);
	};

	static alertConfirm = (title, message, yes, no) => {
		return new Promise((resolve, reject) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						// <div className="mindalay--modal-window">
						// 	<div className="mindalay--modal-window-wrapper">
						// 		<div className="mindalay--modal">
						// 			<div className="d-flex align-items-start">
						// 				{/* <img src={warningSvg} alt="/" /> */}
						// 				<div className="d-block">
						// 					{title ? (
						// 						<Auxiliary>
						// 							<strong>{title}</strong>
						// 						</Auxiliary>
						// 					) : null}
						// 					{message ? <p className="mt-3">{message}</p> : null}
						// 				</div>
						// 			</div>
						// 			<hr />
						// 			<div className="d-flex mt-3 justify-content-end">
						// 				<button
						// 					className="mindalay--btn mindalay--btn-dark mx-1 yes"
						// 					onClick={() => {
						// 						resolve();
						// 						onClose();
						// 					}}
						//>
						// 					{yes}
						// 				</button>
						// 				<button
						// 					className="mindalay--btn mindalay--btn-secondary-outline mx-1 no"
						// 					onClick={() => {
						// 						reject();
						// 						onClose();
						// 					}}
						// >
						// 					{no}
						// 				</button>
						// 			</div>
						// 		</div>
						// 	</div>
						// </div>
						<Modal
							show={true}
							onHide={() => {
								reject();
								onClose();
							}}
						>
							<Modal.Header closeButton>
								<Modal.Title className="text-dark">
									{title ? (
										<Auxiliary>
											<strong>{title}</strong>
										</Auxiliary>
									) : null}
								</Modal.Title>
							</Modal.Header>
							{
								message ?
									<Modal.Body>{message}</Modal.Body>
									: null
							}
							<Modal.Footer>
								<Button
									variant="secondary"
									type="button"
									onClick={() => {
										reject();
										onClose();
									}}
								>
									{no}
								</Button>
								<Button
									variant="primary"
									type="button"
									onClick={() => {
										resolve();
										onClose();
									}}
								>
									{yes}
								</Button>
							</Modal.Footer>
						</Modal>

					);
				},
			});
		});
	};

	static removeAlertBlock = () => {
		var currentBlock = document.getElementById("alertBlockCloseButton");
		if (currentBlock && currentBlock.parentNode) {
			currentBlock.parentNode.removeChild(currentBlock);
		} else {
			return;
		}
	};
}

export default AlertService;
