import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, } from 'react-bootstrap'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import logo from "./../assets/zelara_images/logo-1.png"
import { useSelector } from 'react-redux';
import useWindowSize from '../components/hooks/useWindowSize';
import Users from './components/Users';
import SocialMedia from './components/SocialMedia';

export default function AdminDashboard() {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { rounds } = useSelector(state => state.finance)
  const { user } = useSelector(state => state.user);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize?.width > 991) {
      scrollTop();
    }
  }, [pathname]);

  const scrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 300);
  }

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user])

  return (
    <div className="hero-header py-0 mb-5">
      <div className="container-fluid py-5 pt-3">
        <Row className="align-items-stretch">
          <Col lg={3} className="mb-3 mb-lg-0">
            <Card className='h-100'>
              <CardBody>
                <div className='d-flex justify-content-center'>
                  <img src={logo} className='profile-image' alt='logo' width={180} />
                </div>
                {
                  user && user.fullName && user.fullName.trim().length ?
                    <p className='text-center mt-3 mb-1' style={{ fontWeight: "600", fontSize: "20px" }}>{user?.fullName}</p>
                    : null
                }
                <p className='text-center mt-2'>{user?.email}</p>
                <hr />
                <div className='profile-nav'>
                  <ul>
                    <li className='mb-1'><NavLink to='/admin/users' className={"rounded"}>Users</NavLink></li>
                    <li className='mb-1'><NavLink to='/admin/social-media' className={"rounded"}>Social Media</NavLink></li>
                  </ul>
                </div>

                <hr />
                <div><Link to="/logout" className='w-100 btn btn-danger'>Logout</Link></div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={9} className="mb-3 mb-lg-0">
            <Card className='h-100'>
              <CardBody>
                {
                  pathname.includes("/users") ?
                    <Users /> :
                    pathname.includes("/social-media") ?
                      <SocialMedia /> :
                      null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}



