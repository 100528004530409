import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { PROJECT_NAME, TOKEN_NAME } from '../../helpers/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../../store/reducers/UserReducer';

function useOutsideAlerter(ref, cb) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                cb(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, cb]);
}

const WelcomeAnimation = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const [isShowAnimation, setIsShowAnimation] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setIsShowAnimation);


    useEffect(() => {
        if (user && user.isNew) {
            setTimeout(() => {
                setIsShowAnimation(true);
            }, 1000);
        } else {
            setIsShowAnimation(false);
        }
    }, [user])

    useEffect(() => {
        if (isShowAnimation) {
            const Confettiful = function (el) {
                this.el = el;
                this.containerEl = null;

                this.confettiFrequency = 3;
                this.confettiColors = ['#f2e782'];
                this.confettiAnimations = ['slow', 'medium', 'fast'];

                this._setupElements();
                this._renderConfetti();
            };

            Confettiful.prototype._setupElements = function () {
                const containerEl = document.createElement('div');
                const elPosition = this.el.style.position;

                if (elPosition !== 'relative' || elPosition !== 'absolute') {
                    this.el.style.position = 'relative';
                }

                containerEl.classList.add('confetti-container');

                this.el.appendChild(containerEl);

                this.containerEl = containerEl;
            };

            Confettiful.prototype._renderConfetti = function () {
                this.confettiInterval = setInterval(() => {
                    const confettiEl = document.createElement('div');
                    const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px';
                    const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
                    const confettiLeft = (Math.floor(Math.random() * this.el.offsetWidth)) + 'px';
                    const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];

                    confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation);
                    confettiEl.style.left = confettiLeft;
                    confettiEl.style.width = confettiSize;
                    confettiEl.style.height = confettiSize;
                    confettiEl.style.backgroundColor = confettiBackground;

                    confettiEl.removeTimeout = setTimeout(function () {
                        confettiEl.parentNode.removeChild(confettiEl);
                    }, 3000);

                    this.containerEl.appendChild(confettiEl);
                }, 25);
            };

            const container = document.querySelector('.js-container');
            if (container) {
                new Confettiful(container);
            }

            return () => {
                if (window.confettiful && window.confettiful.confettiInterval) {
                    clearInterval(window.confettiful.confettiInterval);
                }
            };
        }
    }, [isShowAnimation]);

    const hideAnimation = () => {
        const _userData = { ...user };
        _userData.isNew = false;
        dispatch(updateUserData(_userData));
    }

    return (
        isShowAnimation ?
            <div className={`animation-container js-container `}>
                <div className='container p-0'>
                    <div
                        ref={wrapperRef}
                        className=' p-sm-5 p-4 rounded border fw-500 text-uppercase scale-up-center'
                    >
                        <div className='close-icon-block'
                            onClick={hideAnimation}
                        >
                            <IoClose color='#fff' size={20} />
                        </div>
                        <h2 className='text-primary text-nowrap'>Welcome to {PROJECT_NAME}</h2>
                        <p className='text-center mt-4'>
                            You received a reward of <span className='text-primary mx-1 text-nowrap'>50 {TOKEN_NAME}</span> tokens.
                        </p>
                    </div>
                </div>
            </div>
            : null

    );
};

export default WelcomeAnimation;
