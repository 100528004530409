import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Table } from "react-bootstrap";
import MainService from '../../helpers/services/mainService';
import { FaRegCopy } from "react-icons/fa6";
import AlertService from '../../helpers/services/alertService';
import { ApiService } from '../../helpers/services/apiSrevice';
import moment from 'moment/moment';
import useScrollToElement from '../../components/hooks/useScrollToElement';
import useWindowSize from '../../components/hooks/useWindowSize';


export default function TransactionHistory() {

  const [transactions, setTransactions] = useState([]);
  const windowSize = useWindowSize();
  const [shouldScroll, setShouldScroll] = useState(false);
  
  useEffect(() => {
    if (windowSize?.width <= 991) {
      setShouldScroll(true);
    }else{
      setShouldScroll(false);
    }
  }, [windowSize]);

  useScrollToElement("transaction_history", shouldScroll, 180);

  useEffect(() => {
    getTransactions();
  }, [])

  const getStatus = (status) => {
    switch (status) {
      case "Success":
        return <div className='bg-success rounded text-center px-2 py-1'>
          <span className='text-white'>{status}</span>
        </div>
      case "Fail":
        return <div className='bg-danger rounded text-center px-2 py-1'>
          <span className='text-white'>{status}</span>
        </div>
      case "Pending":
        return <div className='bg-info rounded text-center px-2 py-1'>
          <span className='text-white'>{status}</span>
        </div>

      default:
        break;
    }
  }

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      AlertService.alert("success", "Copied!")
    } catch (err) {
      console.error('Failed to copy text to clipboard:', err);
    }
  };

  const getLocalTime = (utcDate) => {
    const localDate = moment.utc(utcDate).local();
    const formattedLocalDate = localDate.format('YYYY-MM-DD HH:mm:ss');
    return formattedLocalDate;
  }

  const getTransactions = () => {
    ApiService.getTransactions().then(response => {
      if (response && response.data) {
        setTransactions(response.data);
      }
    }).catch(error => AlertService.alert("warning", error))
  }

  return (
    <Row id='transaction_history'>
      <Col sm={12}>
        <h4 className='text-white'>Transaction History</h4>
        <hr />
      </Col>
      <Col sm={12}>
        <div className='p-3 mb-2 info-bg rounded mb-3 text-white'>
          <span className='d-block mb-2'><span style={{ fontWeight: "600" }}>Transaction History Overview</span></span>
          <span className='d-block ps-2'>
            This section provides an overview of your transaction history. You can view all your past transactions, including the chain used, transaction hash, amount, status, and creation date. This allows you to keep track of your activities and monitor the status of your transactions.
          </span>
        </div>
      </Col>
      {
        transactions && transactions.length ?
          <Col sm={12}>
            <Table striped bordered hover responsive variant="dark">
              <thead>
                <tr>
                  <th className="text-secondary text-nowrap">Chain</th>
                  <th className="text-secondary text-nowrap">Transaction Hash</th>
                  <th className="text-secondary text-nowrap">Amount</th>
                  <th className="text-secondary text-nowrap">Status</th>
                  <th className="text-secondary text-nowrap">Create Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  transactions && transactions.length ?
                    transactions.map((item, index) => {
                     return <tr key={index}>
                        <td className="text-secondary text-nowrap">{item.chainName}</td>
                        <td className="text-secondary text-nowrap">
                          <div className='d-flex align-items-center'>
                            {MainService.shortenAddress(item.transactionHash)}
                            <FaRegCopy
                              size={14}
                              className='ms-2'
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                copyToClipboard(item.transactionHash)
                              }}
                            />
                          </div>
                        </td>
                        <td className="text-secondary text-nowrap">{item.amount} USD</td>
                        <td className="text-secondary text-nowrap">{getStatus(item.status)}</td>
                        <td className="text-secondary text-nowrap">{getLocalTime(item.createDate)}</td>
                      </tr>
                    })
                    : null
                }
              </tbody>
            </Table>

          </Col>
          : <Col sm={12} className='text-center mt-3'>
            <h4>
              No Transactions Yet
            </h4>
          </Col>
      }


      <Col sm={12}>
        <div className='mt-4'>
          <h5 className='text-white'>What is Transaction History?</h5>
          <p>This section provides a comprehensive overview of all your transactions. Here you can find details about each transaction, including:</p>


          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Chain</span>: The blockchain where the transaction was executed (e.g., Ethereum Mainnet or Binance Smart Chain).</p>
          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Transaction Hash</span>: A unique identifier for the transaction. You can copy this hash and use it to search for the transaction on a blockchain explorer.</p>
          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Amount</span>: The amount of funds involved in the transaction.</p>
          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Status</span>: The current state of the transaction (Success, Fail or Pending).</p>
          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Create Date</span>: The date and time when the transaction was created.</p>


        </div>
      </Col>
      <Col sm={12}>
        <div>
          <hr />
          <h5 className='text-white'>Additional Information:</h5>
          <p className='d-block'>You can use this information to track the status of your transactions and verify their details. If a transaction has a “Pending” status, it means it is still awaiting confirmation on the network. In case of a failure, you might need to take additional actions.</p>
        </div>
      </Col>
    </Row>
  )
}
