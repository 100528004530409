// import React from "react";

// function NeonButton({ onClick = () => {}, title }) {
// 	return (
// 		<div className="neon_button-container">
// 			<button className="neon_button-button" onClick={onClick}>
// 				<span className="neon_button-text">{title}</span>
// 			</button>
// 			<span className="neon_button-background"></span>
// 		</div>
// 	);
// }

// export default NeonButton;


import React from "react";

function NeonButton({ onClick = () => { }, title = "", buttonClassName = "" }) {
	return (
		<button className={`custom-btn btn-15 btn-sm ${buttonClassName}`} onClick={onClick}>
			{title}
		</button>
	);
}

export default NeonButton;
