import React, { useEffect } from 'react'

export default function PrivacyPolicy() {

  useEffect(() => {
    scrollTop();
  }, [])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div className='section mt-3'>
      <div className='container-fluid py-2 mb-5'>
        <div className="row">
          <div className="col-12">
            <div className="h-100">
              <div className="col-12 text-center mb-5">
                <h2 className="display-6 text-primary">Privacy Policy</h2>
                <p className="fs-5 mb-5">This Privacy Policy explains how we collect, use, and protect your personal information when you use our services. By using our services, you agree to the collection and use of information in accordance with this policy.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Information Collection</h4>
                <p>We collect various types of information for different purposes to provide and improve our services:</p>
                <div className="ps-3">
                  <p className="mb-1">• <b>Personal Information:</b> Email address, first name, and last name (if provided).</p>
                  <p className="mb-1">• <b>Usage Data:</b> Information on how our service is accessed and used, such as IP address, browser type, and browsing history.</p>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Use of Data</h4>
                <p>We use the collected data for various purposes:</p>
                <div className="ps-3">
                  <p className="mb-1">• To provide and maintain our services.</p>
                  <p className="mb-1">• To notify you about changes to our services.</p>
                  <p className="mb-1">• To allow you to participate in interactive features of our services when you choose to do so.</p>
                  <p className="mb-1">• To provide customer support.</p>
                  <p className="mb-1">• To gather analysis or valuable information so that we can improve our services.</p>
                  <p className="mb-1">• To monitor the usage of our services.</p>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Data Security</h4>
                <p>We value your trust in providing us your personal data, so we strive to use commercially acceptable means of protecting it. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Changes to This Privacy Policy</h4>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Contact Us</h4>
                <p>If you have any questions about this Privacy Policy, please contact us at: support.zelara@gmail.com.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">User Responsibilities</h4>
                <p>Users are expected to follow the rules and guidelines set forth in this Privacy Policy. Prohibited actions include, but are not limited to:</p>
                <div className="ps-3">
                  <p className="mb-1">• Misuse or abuse of the services.</p>
                  <p className="mb-1">• Violation of applicable laws and regulations.</p>
                  <p className="mb-1">• Unauthorized access to or tampering with other users' data.</p>
                </div>
                <p>Users are responsible for their actions while using our services and any consequences that arise from violating these rules.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Access and Deletion of Data</h4>
                <p>Users have the right to request access to their personal data and request its deletion. To exercise these rights, please contact us at: support.zelara@gmail.com.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Third-Party Services</h4>
                <p>We may use third-party services for various purposes, such as analytics or advertising. These third parties may have access to your personal information. We do not control their privacy practices and encourage you to review their privacy policies.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Cookies</h4>
                <p>Our services use cookies to enhance your experience. Cookies are files with a small amount of data that are stored on your device. You can choose to accept or refuse cookies through your browser settings. If you refuse cookies, some parts of our services may not function properly.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">International Users</h4>
                <p>If you are located outside of the country where our servers are based, your data may be transferred to and processed in that country. By using our services, you consent to the transfer of your data to that country.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Children's Privacy</h4>
                <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal data from children under 18. If we become aware that we have inadvertently collected personal data from a child under 18, we will take prompt action to delete that information.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">Data Collection from Third Parties</h4>
                <p>We may collect data from third parties to provide a more comprehensive service. This data is subject to the same privacy policies and practices as the data collected directly from users.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
