import React from "react";
import { Modal } from "react-bootstrap";
import {
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    XIcon,
    TelegramIcon,
    WhatsappIcon,
    LinkedinIcon,
    RedditIcon,
} from "react-share";
import { IoCloseSharp } from "react-icons/io5";
import { PROJECT_NAME } from "../../helpers/constants/constants";

export default function ShareModal({ shareUrl = "https://zelara.org", show, handleClose = () => { } }) {
    return (
        <Modal className="share-modal" show={show} onHide={handleClose} centered>
            <Modal.Header>
                <Modal.Title>Join the {PROJECT_NAME} Movement</Modal.Title>
                <div>
                    <IoCloseSharp style={{ cursor: "pointer" }} color="#fff" size={25} onClick={handleClose} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-center mb-2">Share this link to introduce your friends to {PROJECT_NAME}, earn rewards, and receive a percentage from their deposits!</h6>
                <div className="d-flex flex-wrap gap-4 p-3 justify-content-center">
                    <TelegramShareButton url={shareUrl}>
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>

                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                    <TwitterShareButton url={shareUrl}>
                        <XIcon size={32} round />
                    </TwitterShareButton>

                    <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>

                    <RedditShareButton url={shareUrl} windowWidth={660} windowHeight={460}>
                        <RedditIcon size={32} round />
                    </RedditShareButton>

                    <EmailShareButton url={shareUrl}>
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                </div>
            </Modal.Body>
        </Modal>
    );
}
