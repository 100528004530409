import axios from "axios";
import store from "../../store/index";
import { setToken, setUserData } from "../../store/reducers/UserReducer";
import AlertService from "./alertService";

const API_URL_KEY = process.env.REACT_APP_API_URL_KEY || "";

const api = axios.create({
	baseURL: API_URL_KEY,
	withCredentials: false,
});

api.interceptors.request.use(async config => {
	config.headers = {
		"Authorization": localStorage.getItem("token")
			? localStorage.getItem("token")
			: "",
		"ngrok-skip-browser-warning": "true",
		"Access-Control-Allow-Origin": "true",
		"Referral": localStorage.getItem("referral")
			? localStorage.getItem("referral")
			: ""
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (response && response.data && response.data?.status >= 300 && response.data?.status < 400) {
			return Promise.reject("Bad request");
		}
		if (response && response.data && response.data?.status >= 400 && response.data?.status < 500) {
			return Promise.reject("Bad request");
		}
		if (response && response.data && response.data?.status >= 500) {
			if (response.data?.message) {
				return Promise.reject(response.data?.message || "Internal Server Error");
			}
			return Promise.reject("Sorry, something went wrong!");
		}

		return Promise.resolve(response?.data)
	},
	error => {
		if (error.code === "ERR_NETWORK") {
			// return Promise.reject("401 TIGO");
			if (localStorage.getItem("user") || localStorage.getItem("token")) {
				logout();
			}
			return;
		}
		if (error.response) {
			// return Promise.reject("401 TIGO");
			if (error.response.status === 401) {
				if (localStorage.getItem("user") || localStorage.getItem("token")) {
					logout();
				}
			}
			const _error = error.response.data.message;
			return Promise.reject(_error || "");
		} else {
			return Promise.reject("Unrecognized error")
		}
	},
);

const logout = () => {
	localStorage.clear();
	store.dispatch(setUserData(null));
	store.dispatch(setToken(""));
	AlertService.alert("warning", "Your session has expired. Please log in again to continue.")
};

class ApiService {
	static userRegistration(data) {
		return api.post(`/api/v1/User/UserRegistration`, data);
	}
	static getCurrentUser(data) {
		return api.get(`/api/v1/User/Current`, data);
	}
	static forgotPassword(email) {
		return api.post(`/api/v1/User/GetRestorePasswordUrl?email=${email}`);
	}
	static getTokensByChainId(chainId) {
		return api.get(`/api/v1/Transaction/GetChain?chainId=${chainId}`);
	}
	static makeTransaction(data) {
		return api.post(`/api/v1/Transaction/Create`, data);
	}
	static getTransactions() {
		return api.get(`/api/v1/Transaction/GetUserTransactions`);
	}
	static updateUserData(data) {
		return api.put(`/api/v1/User/Update`, data);
	}
	static updateUserPassword(data) {
		return api.put(`/api/v1/User/UpdatePassword`, data);
	}
	static restorePassword(data) {
		return api.put(`/api/v1/User/RestorePassword`, data);
	}
	static checkKey(key, email) {
		return api.get(`/api/v1/User/AccessRestorePassword?key=${key}&email=${email}`);
	}
	static getUserBalances() {
		return api.get(`/api/v1/Balance/Get`);
	}
	static tokenSwap(data) {
		return api.post(`/api/v1/Balance/Swap`, data);
	}
	static getRounds() {
		return api.get(`/api/v1/Round/GetAll`);
	}
	static getReferrals() {
		return api.get(`/api/v1/User/GetReferrals`);
	}
	static getInvitationUrl() {
		return api.get(`/api/v1/User/GetReferralUrl`);
	}
	static getKeyMetrics() {
		return api.get(`/api/v1/home/getKeyMetrics`);
	}
	static googleLogin(data) {
		return api.post(`/api/v1/User/GoogleAuth`, data);
	}
	static getTasks() {
		return api.get(`/api/v1/SocialMedia/GetAll`);
	}
	static onFollow(data) {
		return api.post(`/api/v1/UserSocialMedia/Create`, data);
	}
	static onClaim(socialMediaId) {
		return api.post(`/api/v1/UserSocialMedia/UpdateStatus?socialMediaId=${socialMediaId}`);
	}
	static getUserSocialMedia(data) {
		return api.post(`/api/v1/UserSocialMedia/GetList`, data);
	}
	static getUsersAsAdmin(data) {
		return api.post(`/api/v1/User/GetPagedList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data.filter);
	}
	static getUsersCount() {
		return api.get(`/api/v1/User/GetUsersCount`);
	}
	static getUsersFilter() {
		return api.get(`/api/v1/User/GetFilter`);
	}
	static confirmUserSocialMedia(socialMediaId, userId, status) {
		return api.post(`/api/v1/UserSocialMedia/UpdateStatus?socialMediaId=${socialMediaId}&userId=${userId}&status=${status}`);
	}
}

export { ApiService, logout };
