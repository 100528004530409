import React, { useEffect, useState, useRef } from "react";
import MainService from "./../helpers/services/mainService";
import logo from "./../assets/zelara_images/logo-1.png";
import featureImage from "./../assets/zelara_images/1.jpg"
import tokenomicImage from "./../assets/zelara_images/2.jpg"
import plansImage from "./../assets/zelara_images/3.jpg"
import journeyImage from "./../assets/zelara_images/4.png"
import Deposit from "./components/Deposit";
import { Table } from "react-bootstrap";
import { MdOutlineBackHand } from "react-icons/md";
import { PiMoneyLight } from "react-icons/pi";
import { TbSocial } from "react-icons/tb";
import { ApiService } from "./../helpers/services/apiSrevice"
import AlertService from "../helpers/services/alertService";
import { PROJECT_NAME, PROJECT_NAME_UP, TOKEN_NAME } from "../helpers/constants/constants";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { GoPeople } from "react-icons/go";
import { VscArrowSwap } from "react-icons/vsc";
import { PiHandCoinsLight } from "react-icons/pi";
import { BiCopy } from "react-icons/bi";
import { GoMail } from "react-icons/go";
import { useLocation } from "react-router-dom";


export default function Home() {

	const sectionRefs = useRef([]);
	const { rounds } = useSelector(state => state.finance)
	const { pathname } = useLocation();
	const [values, setValues] = useState({
		people: 0,
		transactions: 0,
		coins: 0,
		// marketCap: 0,
	})

	useEffect(() => {
		getKeyMetrics();
	}, [])

	useEffect(() => {
		setTimeout(() => {
			scrollTop();
		}, 100);
	}, [pathname]);

	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	const getKeyMetrics = () => {
		ApiService.getKeyMetrics().then((response) => {
			if (response && response.data) {
				setValues(response.data);
			} else {
				setValues({
					people: 0,
					transactions: 0,
					coins: 0,
					// marketCap: 0,
				})
			}
		}).catch(error => AlertService.alert("warning", error))

	}

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			AlertService.alert("success", "Copied!");
		} catch (err) {
			console.error('Failed to copy text to clipboard:', err);
		}
	};

	useEffect(() => {
		let isScrolling = false;

		const sections = document.querySelectorAll("section");
		let currentSectionIndex = 0;

		const handleScroll = event => {
			if (isScrolling) return;

			const delta = event.deltaY;
			if (delta > 0) {
				// Scrolling down
				if (currentSectionIndex < sections.length - 1) {
					currentSectionIndex++;
					scrollToSection(currentSectionIndex);
				}
			} else if (delta < 0) {
				// Scrolling up
				if (currentSectionIndex > 0) {
					currentSectionIndex--;
					scrollToSection(currentSectionIndex);
				}
			}
		};

		const scrollToSection = index => {
			isScrolling = true;
			sections[index].scrollIntoView({ behavior: "smooth" });

			// Wait until the scroll is finished to allow further scrolling
			setTimeout(() => {
				isScrolling = false;
			}, 1000); // Adjust the timeout to match the scroll speed
		};

		window.addEventListener("wheel", handleScroll);

		return () => {
			window.removeEventListener("wheel", handleScroll);
		};
	}, []);



	return (
		<div className="sm-center">

			<div
				className="section hero-header mt-3">
				<div className="container">
					<div className="row flex-column-reverse flex-lg-row g-5 align-items-center">
						<div className="col-lg-6">
							<h1 className="display-4 mb-3 animated slideInDown text-primary sm-center">
								Introduction to the Crypto Project
							</h1>
							<p className="animated slideInDown mb-4 sm-center">
								Welcome to the <span className="text-white">{PROJECT_NAME_UP}</span> project! We present a new crypto project
								designed to provide crypto enthusiasts with the opportunity to
								earn passive income.
							</p>
							<Deposit buttonClassName="w-sm-100" />
						</div>
						<div className="col-lg-6 animated fadeIn d-flex justify-content-center">
							<img
								className="img-fluid animated pulse infinite main-image-sm"
								style={{ animationDuration: "3s", maxWidth: "300px" }}
								src={logo}
								alt="/"
							/>
						</div>
					</div>
				</div>
			</div>


			<div className="section align-items-start mt-3">
				<div className="container py-3">
					<div
						className="text-center mx-auto wow fadeInUp"
						data-wow-delay="0.1s">
						<h2 className="display-6 text-primary ">Token Sale Rounds</h2>
						<p className="fs-5 mb-5">Our token sale is divided into multiple rounds, each offering a unique opportunity to purchase tokens at different rates. Here’s a brief overview of the upcoming rounds:</p>
					</div>
					<div className="row g-4">
						{
							rounds && rounds.length ?
								rounds.map((round, index) => {
									return <div
										key={index}
										className="col-lg-4 col-md-6 wow fadeInUp"
										data-wow-delay="0.1s">
										<div className={`p-4  border rounded ${round.isActive ? "service-item" : "disabled"}`}>
											<div className="d-flex gap-3 align-items-end">
												<h3 className="text-primary display-4">{index + 1}</h3>
												<h2 className="mb-3 text-white" style={{ letterSpacing: "3px" }}>ROUND</h2>
											</div>
											<div className="">
												<p style={{ marginBottom: "5px" }}>
													<span>{moment(round.startDate).format("ll")}</span>&nbsp;-&nbsp;<span>{moment(round.endDate).format("ll")}</span>
												</p>
												<p className="text-white" style={{ fontSize: "20px" }}>
													1 {TOKEN_NAME} = {round.rate} USDT
												</p>
											</div>
											<div className="mb-4">
												<span className="d-block text-nowrap">Available Tokens: <span className="text-primary">{MainService.formatCurrency(round.availableCoinsAmount, 2)}</span></span>
											</div>
											<div className="d-flex justify-content-center">
												<Deposit buttonClassName="w-100" blockClassName="w-100" />
											</div>
										</div>
									</div>
								})
								: null
						}
					</div>
				</div>
			</div>


			<div className="section align-items-start mt-3">
				<div className="container py-3">
					<div
						className="text-center mx-auto wow fadeInUp"
						data-wow-delay="0.1s">
						<h2 className="display-6 text-primary">Token Sale and Rewards Program</h2>
						<p className=" fs-5 mb-5">Participate, Stake, and Earn with Our Multi-Stage Token Sale and Lucrative Referral System</p>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3">
							<div className="p-3">
								<h5 className="text-primary fw-400">Rounds</h5>
								<p>1. The token sale takes place in three rounds.</p>
								<p>2. Each round has a predetermined token price.</p>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="p-3">
								<h5 className="text-primary fw-400">Staking</h5>
								<p>1. The tokens you acquire can be staked at 1.5% daily.</p>
								<p>2. Profit is calculated based on compound interest.</p>
								<p>3. You can withdraw your staked tokens to your main balance at any time.</p>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="p-3">
								<h5 className="text-primary fw-400">Referral System</h5>
								<p>1. Each new user will receive a gift of 50 tokens.</p>
								<p>2. For each user you invite, you will receive a reward of <span className="text-primary">500</span> tokens.</p>
								<p>3. When your referral buys tokens, you will receive <span className="text-primary">10%</span> of the amount of tokens they purchased.</p>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="p-3">
								<h5 className="text-primary fw-400">Token Withdrawal</h5>
								<p>1. The withdrawal of tokens to TON-supported wallets will be available once the number of users reaches 100,000 or at the end of the second round.</p>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div
				className="section mt-3">
				<div className="container py-2">
					<div className="row g-5 align-items-center">
						<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
							<img className="img-fluid rounded" src={featureImage} alt="/" />
						</div>
						<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
							<div className="h-100">
								<h1 className="display-6 text-primary ">Key Features and Benefits of the Project</h1>
								<p className="fs-5 mb-5">
									Our project offers an innovative solution to address existing problems.
								</p>

								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Staking
									</h5>
									<div className="ps-3">
										<span>The tokens you acquire can be staked to earn passive income.</span>
									</div>
								</div>

								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Withdrawals
									</h5>
									<div className="ps-3">
										<span>You can withdraw your tokens from the system to your wallet at any time.</span>
									</div>
								</div>

								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Three Sales Stages
									</h5>
									<div className="ps-3">
										<span>
											The sales will occur in three stages according to a preplanned scenario.
											There will be a maximum purchase limit to ensure everyone can acquire tokens.
											More holders mean greater potential.
										</span>
									</div>
								</div>

								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										News and Updates
									</h5>
									<div className="ps-3">
										<span>
											We strive to surprise you
											each week with new platform
											features, offering various
											ways to earn.
										</span>
									</div>
								</div>

								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Referral System
									</h5>
									<div className="ps-3">
										<span>

											Our referral system allows you to earn <span className="text-primary">500</span> tokens for each referred user and <span className="text-primary">10%</span> of their purchase amount.
										</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>


			<div
				className="section mt-3"
				id="section2"
				ref={el => (sectionRefs.current[0] = el)}>
				<div className="container py-3">
					<div className="row">

						<div className="col-12">
							<div className="h-100">
								<div className="col-12 text-center mb-5">
									<h2 className="display-6 text-primary">Roadmap</h2>
									<p className="fs-5 mb-5">The project is in active development, and we are following a clear plan to achieve our set goals.</p>
								</div>

								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Q1 2024: Research and Planning
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Market research and competitor analysis: Identifying key players and studying successful strategies.</p>
										<p className="mb-1">○ Team formation: Hiring specialists in blockchain technology, cryptography, marketing, and PR.</p>
										<p className="mb-1">○ Product concept development: Defining the unique characteristics and advantages of our crypto project.</p>
									</div>
								</div>
								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Q2 2024: Start of Development
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Platform architecture design: Creating technical documentation and selecting technologies.</p>
										<p className="mb-1">○ Development of MVP (Minimum Viable Product): Creating a basic version of the platform for initial testing.</p>
										<p className="mb-1">○ Creation of Whitepaper: Detailed description of the technical implementation, economic model, and roadmap.</p>
									</div>
								</div>
								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Q3 2024: Testing and Improvement
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Internal MVP testing: Identifying and fixing bugs, improving user interface and functionality.</p>
										<p className="mb-1">○ Alpha testing with a limited group of users: Collecting feedback and refining the product based on received data.</p>
										<p className="mb-1">○ Development of marketing strategy: Preparing for the project launch, creating promotional materials, and attracting initial investors.</p>
									</div>
								</div>
								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Q4 2024: Platform Launch
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Official platform launch: Presenting the product to a wide audience, conducting an advertising campaign.</p>
										<p className="mb-1">○ Start of public token sales (ICO/IEO): Conducting a campaign to attract investments through token sales.</p>
									</div>
								</div>
								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Q1 2025: Listing, Scaling and Development
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Token <span className="text-primary">listing</span> on exchanges: Placing tokens on major cryptocurrency exchanges to ensure liquidity.</p>
										<p className="mb-1">○ Expanding platform functionality: Implementing new features and services based on user feedback.</p>
										<p className="mb-1">○ Partnerships and integrations: Entering agreements with other projects and companies to expand the ecosystem.</p>
										<p className="mb-1">○ Team scaling: Hiring additional specialists to support the growth and development of the project.</p>
									</div>
								</div>
								<div className="mb-3">
									<h5 className="text-primary fw-400" >
										Q2 2025: International Expansion
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Entering international markets: Adapting the platform to the requirements of various jurisdictions and launching localized versions.</p>
										<p className="mb-1">○ Participation in international conferences and forums: Promoting the project on the global stage, attracting new users and partners.</p>
										<p className="mb-1">○ Continuous security improvement: Regular security audits and implementing advanced technologies to protect user data.</p>
									</div>
								</div>
								<div className="mb-3">
									<h5 className="text-primary fw-400">
										Q3 2025 and Beyond: Sustainable Development
									</h5>
									<div className="ps-3">
										<p className="mb-1">○ Community support and development: Organizing events, contests, and loyalty programs for platform users.</p>
										<p className="mb-1">○ Continuous updates and improvements: Implementing new technologies and enhancements to maintain competitiveness.</p>
										<p className="mb-1">○ Ecosystem expansion: Creating additional services and products based on the platform to meet user needs.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div
				className="section mt-3">
				<div className="container py-3">
					<div className="row g-5 align-items-center">
						<div className="col-lg-4 text-center">
							<img className="img-fluid rounded" src={tokenomicImage} alt="/" />
						</div>
						<div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
							<div className="h-100">
								<h2 className="display-6 text-primary ">Tokenomics and Token Distribution</h2>
								<p className="fs-5 mb-5">
									Tokenomics is a key aspect of our crypto project, defining the economic
									model and the use of tokens within our platform. We have developed a
									system that ensures stability, liquidity, and long-term value for our users
									and investors.
								</p>

								<div className="mb-4">
									<h5>
										Main Characteristics of Tokens:
									</h5>
									<div className="ps-3 ">
										<div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
											<h6 className="mb-1">
												<span>• &nbsp; Token Name:</span>
											</h6>
											<p className="mb-0 text-primary">
												{PROJECT_NAME}
											</p>
										</div>
										<div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
											<h6 className="mb-1">
												<span>• &nbsp; Token Symbol:</span>
											</h6>
											<p className="mb-0 text-primary">
												{TOKEN_NAME}
											</p>
										</div>
										<div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
											<h6 className="mb-1">
												<span>• &nbsp; Token Type:</span>
											</h6>
											<p className="mb-0 text-primary">
												TON
											</p>
										</div>
										<div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
											<h6 className="mb-1">
												<span>• &nbsp; Total Supply:</span>
											</h6>
											<p className="mb-0 text-primary">
												10.000.000.000
											</p>
										</div>
										<div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
											<h6 className="mb-1">
												<span>• &nbsp; Contract address:</span>
											</h6>
											<p className="mb-0 text-primary" style={{ wordBreak: "break-word", textAlign: "left" }}>
												EQBgUdLrhQW5SQ1F0lNPNUyCcpe5ZOR6CG6cpkIMBQJs3yds
												<BiCopy
													className="ms-2 cursor-pointer"
													onClick={() => copyToClipboard("EQBgUdLrhQW5SQ1F0lNPNUyCcpe5ZOR6CG6cpkIMBQJs3yds")}
												/>
											</p>
										</div>
									</div>
								</div>
								<div className="mb-3">
									<h5>
										Token Distribution:
									</h5>
									<div className="ps-3 mt-3">
										<Table striped bordered hover responsive variant="dark">
											<thead>
												<tr>
													<th className="text-secondary">Target</th>
													<th className="text-secondary">Percent</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="text-secondary">Token Sale (ICO/IEO)</td>
													<td className="text-secondary">20%</td>
												</tr>
												<tr>
													<td className="text-secondary">Team and Advisors</td>
													<td className="text-secondary">17%</td>
												</tr>
												<tr>
													<td className="text-secondary">Platform and Ecosystem</td>
													<td className="text-secondary">16%</td>
												</tr>
												<tr>
													<td className="text-secondary">Marketing and Community Development</td>
													<td className="text-secondary">17%</td>
												</tr>
												<tr>
													<td className="text-secondary">Reward for Staking and Referrals</td>
													<td className="text-secondary">10%</td>
												</tr>

											</tbody>
										</Table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="section align-items-start mt-3">
				<div className="container py-3">
					<div
						className="text-center mx-auto"
						data-wow-delay="0.1s">
						<h2 className="display-6 text-primary">{PROJECT_NAME} Key Metrics</h2>
						<p className=" fs-5 mb-5">Here’s an overview of the key metrics that highlight our platform's growth and performance.</p>
					</div>
					<div className="row">
						<div
							className="col-lg-4 col-md-6 text-center mb-4 mb-sm-0 mt-md-0 mt-4"
							data-wow-delay="0.1s">
							<div className="mb-2 mb-sm-4"><GoPeople size={45} className="text-primary" /></div>
							<span className="display-6">
								{MainService.formatWithSuffix(values.people, 2)}
							</span>
							<p className="fs-5 text-primary mb-0">People Trusting {PROJECT_NAME}</p>
						</div>
						<div
							className="col-lg-4 col-md-6 text-center mb-4 mb-sm-0 mt-md-0 mt-4"
							data-wow-delay="0.1s">
							<div className="mb-2 mb-sm-4"><VscArrowSwap size={45} className="text-primary" /></div>
							<span className="display-6">
								{MainService.formatWithSuffix(values.transactions, 2)}
							</span>
							<p className="fs-5 text-primary mb-0">Total Transactions</p>
						</div>
						<div
							className="col-lg-4 col-md-6 text-center mb-4 mb-sm-0 mt-md-0 mt-4"
							data-wow-delay="0.1s">
							<div className="mb-2 mb-sm-4"><PiHandCoinsLight size={45} className="text-primary" /></div>
							<span className="display-6">
								{MainService.formatWithSuffix(values.coins, 2)}
							</span>
							<p className="fs-5 text-primary mb-0">Available Coins</p>
						</div>
						{/* <div
							className="col-lg-3 col-md-6 text-center mb-4 mb-sm-0"
							data-wow-delay="0.1s">
							<div className="mb-2 mb-sm-4"><PiCurrencyCircleDollarLight size={45} className="text-primary" /></div>
							<span className="display-6">
								{MainService.formatWithSuffix(values.marketCap, 2)}
							</span>
							<p className="fs-5 text-primary mb-0">{PROJECT_NAME} Market Cap</p>
						</div> */}
					</div>
				</div>
			</div>


			<div
				className="section mt-3">
				<div className="container py-3">
					<div className="row g-5 align-items-center">

						<div className="col-lg-6" data-wow-delay="0.5s">
							<div className="h-100">
								<h2 className="display-6 text-primary ">
									The project plans to raise
									funds for further
									development and expansion.
								</h2>
								<p className="fs-5 mb-5">
									The project plans to attract investments for further development and
									expansion.
								</p>

								<div className="mb-3">
									<div className="d-flex align-items-center gap-4">
										<div className="triangle-block">
											<div className="triangle-up"></div>
											<div className="triangle-down"></div>
											<div className="h-100 w-100 d-flex justify-content-center align-items-center">
												<h2 className="fw-normal mt-4">1</h2>
											</div>
										</div>
										<div>
											<h4>
												Stage 1
											</h4>
											<p>
												Conducting a private token sale for early investors.
											</p>
										</div>
									</div>
								</div>

								<div className="mb-3">
									<div className="d-flex align-items-center gap-4">
										<div className="triangle-block">
											<div className="triangle-up"></div>
											<div className="triangle-down"></div>
											<div className="h-100 w-100 d-flex justify-content-center align-items-center">
												<h2 className="mt-4 display-6">2</h2>
											</div>
										</div>
										<div>
											<h4>
												Stage 2
											</h4>
											<p>
												Public token sale on leading cryptocurrency exchanges.
											</p>
										</div>
									</div>
								</div>

								<div className="mb-3">
									<div className="d-flex align-items-center gap-4">
										<div className="triangle-block">
											<div className="triangle-up"></div>
											<div className="triangle-down"></div>
											<div className="h-100 w-100 d-flex justify-content-center align-items-center">
												<h2 className="fw-normal mt-4">3</h2>
											</div>
										</div>
										<div>
											<h4>
												Stage 3
											</h4>
											<p>
												Attracting investments from venture capital funds and institutional investors.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<img className="img-fluid rounded" src={plansImage} alt="/" />
						</div>
					</div>
				</div>
			</div>


			<div
				className="section mt-3">
				<div className="container py-3">
					<div className="row g-5 align-items-center">

						<div className="col-lg-5 order-lg-1 order-2">
							<img className="img-fluid rounded" src={journeyImage} alt="/" />
						</div>

						<div className="col-lg-7 order-lg-2 order-1">
							<div className="h-100 text-center">
								<h2 className="display-6 text-primary ">Be Part of Our Exciting Journey</h2>
								<p className="fs-5 mb-5">
									We believe our project has the potential for considerable growth and advancement.
								</p>

								<div>

									<div className="mb-0 mb-md-4 md-center">
										<div className="mb-2">
											<MdOutlineBackHand size={40} className="text-primary" />
										</div>
										<h4 className="mb-1">
											Join Us
										</h4>
										<span>Join our community to stay updated with all the news.</span>
									</div>

									<div className="mb-0 mb-md-4 md-center">
										<div className="mb-2">
											<PiMoneyLight size={45} className="text-primary" />
										</div>
										<h4 className="mb-1">
											Invest
										</h4>
										<span>Become a part of our project by investing in the {PROJECT_NAME} token.</span>
									</div>

									<div className="mb-0 mb-md-4 md-center">
										<div className="mb-2">
											<TbSocial size={40} className="text-primary" />
										</div>
										<h4 className="mb-1">
											Connect with Us
										</h4>

										<span>
											Follow us on social media:
											&nbsp;
											<a href="https://www.facebook.com/profile.php?id=61566940469633" className="text-primary">Facebook</a>,
											&nbsp;
											<a href="https://www.instagram.com/zelaratoken" className="text-primary">Instagram</a>,
											&nbsp;
											<a href="https://x.com/ZelaraToken" className="text-primary">X (Twitter)</a>
											&nbsp;
											and
											&nbsp;
											<a href="https://t.me/zelaratoken" className="text-primary">Telegram</a>
											.
										</span>
									</div>

									<div className="mb-0 mb-md-4 md-center">
										<div className="mb-2">
											<GoMail size={40} className="text-primary" />
										</div>
										<h4 className="mb-1">
											Contact Information
										</h4>
										<p>Customer Support: <a className="text-primary" href="mailto:customers@zelara.org">customers@zelara.org</a></p>
										<p>Business Inquiries: <a className="text-primary" href="mailto:business@zelara.org">business@zelara.org</a></p>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div
				className="section mt-3">
				<div className="container py-3">
					<div
						className="row"
					>
						<div className="col-12 text-center">
							<h2 className="display-6 text-primary mb-3">Listing</h2>
							<p className=" fs-5 mb-5 text-center">We are negotiating with top decentralized exchanges. The token listing is planned for late January 2025. We will notify you in advance.</p>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}
