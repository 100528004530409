import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { ApiService } from '../helpers/services/apiSrevice';
import AlertService from '../helpers/services/alertService';
import logo_5 from "./../assets/unauth_pages_images/ai_5.jpg"

export default function RestorePassword() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const email = searchParams.get('email');

  const { user } = useSelector(state => state.user);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [values, setValues] = useState({
    password: "",
  })

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user])

  useEffect(() => {
    checkKey(key, email);
  }, [key, email])

  const checkKey = (key) => {
    if (!key || !email) { return false; }
    ApiService.checkKey(key, email).then(response => {
      setIsShowForm(true);
    }).catch(error => AlertService.alert("warning", error)).finally(() => {
      setIsLoading(false);
    });
  }

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.value
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!key) { return false; }
    if (!values.password.trim().length || isLoading) {
      setIsInvalidSubmit(true);
      return false;
    }
    setIsLoading(true);
    ApiService.restorePassword({ password: btoa(values.password), key: key }).then(response => {
      navigate("/login");
      AlertService.alert("success", "Your password has been successfully updated!")
    }).catch(error => AlertService.alert("warning", error)).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div>
      <div className="container-fluid hero-header py-lg-5 py-0 mb-5 unauth-container">
        <div className="container py-5">

          <Card className={`p-0 ${!isShowForm ? "disabled" : ""}`}>
            <CardBody className='p-0' style={{ height: "400px" }}>
              <div className='d-flex h-100'>
                <div className="w-100 d-none d-lg-block image-block">
                  <div
                    className='bg-image'
                    style={{
                      backgroundImage: `url(${logo_5})`
                    }}>
                  </div>
                </div>
                <div className='w-100 content-block'>
                  <h1 className="display-4 mb-3">Restore Password</h1>
                  <div>
                    <Form onSubmit={onSubmit}>
                      <Form.Group className="position-relative" controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={isShowPassword ? "text" : "password"}
                          placeholder="Enter password"
                          value={values.password}
                          onChange={(event) => onChange(event, "password")}
                          className={isInvalidSubmit && !values.password.trim().length ? "error-border" : ""}
                        />
                        <div className='eye' onClick={() => setIsShowPassword(!isShowPassword)}>
                          {
                            isShowPassword ?
                              <IoEyeOff size={20} />
                              :
                              <IoEye size={20} />
                          }
                        </div>
                      </Form.Group>
                      <small className='d-block mb-3 mt-1'>
                        Create a unique and secure password. Use a mix of letters, numbers, and symbols. Make it memorable but hard to guess.
                      </small>
                      {
                        isLoading ?
                          <Button className=' mb-3 px-4 w-100' variant="primary" type="submit">
                            <span>
                              Loading
                              <Spinner className='ms-2' animation="border" size='sm' />
                            </span>
                          </Button>
                          : <Button className=' mb-3 px-4 w-100' variant="primary" type="submit" disabled={isLoading}>
                            Send
                          </Button>
                      }
                    </Form>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

    </div>
  )
}
