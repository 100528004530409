import React from 'react'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { WagmiProvider } from 'wagmi'
import { mainnet, bsc, arbitrum, avalanche, base, optimism, polygon } from "wagmi/chains"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


const queryClient = new QueryClient();
const projectId = process.env.REACT_APP_PROJECT_ID;

const metadata = {
    name: 'Web3Modal',
    description: 'WalletConnect',
    url: 'https://zelara.org',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

export const chains = [mainnet, bsc, arbitrum, avalanche, base, optimism, polygon];

export const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    auth:{
        email: false,
        socials: [],
        showWallets: true,
        walletFeatures: true,
    }
})

createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

export default function WagmiComponent({ children }) {
    return (
        <WagmiProvider config={config} >
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WagmiProvider>
    )
}
