// modalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isDepositModalOpen: false,
    },
    reducers: {
        openDepositModal: (state) => {
            state.isDepositModalOpen = true;
        },
        closeDepositModal: (state) => {
            state.isDepositModalOpen = false;
        },
    },
});

export const { openDepositModal, closeDepositModal } = modalSlice.actions;
export default modalSlice.reducer;
