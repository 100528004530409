import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import logo from "./../../assets/zelara_images/logo-1.png";
import { MdMenu } from "react-icons/md";
import { useSelector } from "react-redux";
import useWindowSize from "../hooks/useWindowSize";
import { IoHomeOutline } from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineLogin } from "react-icons/md";
import { MdOutlineLogout } from "react-icons/md";
import GoogleLoginButton from "../../pages/components/GoogleLoginButton";
import { ADMIN_USER_TYPE_ID, CLIENT_USER_TYPE_ID, PROJECT_NAME } from "../../helpers/constants/constants";
import Deposit from "../../pages/components/Deposit";
import { useAccount } from "wagmi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdCurrencyExchange } from "react-icons/md";



function Header(props) {

	const { address, chain, chainId, isConnected } = useAccount();
	const { pathname } = useLocation();
	const windowSize = useWindowSize();
	const [isActive, setIsActive] = useState(false);
	const { user } = useSelector(state => state.user);
	const [isShowMobilemenu, setIsShowMobilemenu] = useState(false);

	useEffect(() => {
		if (windowSize.width > 991) {
			setIsShowMobilemenu(false);
		}
	}, [windowSize])

	useEffect(() => {
		const handleScroll = () => {
			if (pathname.includes("/login") || pathname.includes("/registration") || pathname.includes("/forgot-password") || pathname.includes("/restore-password")) {
				return false;
			}
			if (window.scrollY > 100) {
				setIsActive(true);
			} else {
				setIsActive(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [pathname]);


	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	return (
		<div>
			<div className="d-none">
				{
					!user ? <GoogleLoginButton /> : null
				}
			</div>
			<header>
				<nav
					className={`${isActive ? "active-header" : ""
						} navbar zelara_header navbar-expand-lg navbar-light sticky-top p-0 px-4 px-lg-5 `}
					style={{ flexWrap: "nowrap" }}
				>
					<Link
						href="/"
						className="navbar-brand d-flex align-items-center logo-block"
						onClick={scrollTop}
					>
						<h2 className="m-0 text-primary d-flex align-items-center">
							<img
								className="img-fluid me-2 logo"
								src={logo}
								alt="logo"
								style={{ width: "40px" }}
							/>
							<span className="text-primary">{PROJECT_NAME}</span>
						</h2>
					</Link>
					<div className="d-flex">
						{
							user ?
								<div className="d-flex d-lg-none align-items-center">
									{
										isConnected ? <w3m-button balance="hide" /> :
											<Deposit buttonType={2} buttonTitle="Connect Wallet" size="sm" buttonVariant="warning" buttonClassName="px-3 me-2 btn btn-primary btn-sm" />
									}
								</div>
								: null

						}
						<button
							type="button"
							className="navbar-toggler p-0"
							onClick={() => setIsShowMobilemenu(!isShowMobilemenu)}
						>
							<span className="navbar-toggler-icon text-secondary">
								<MdMenu size={30} />
							</span>
						</button>
					</div>
					<div className="collapse navbar-collapse" id="navbarCollapse">
						<div className="navbar-nav ms-auto py-4 py-lg-0 align-items-center zelara_headre_menu">
							<NavLink to="/" className="nav-item nav-link text-secondary">
								Home
							</NavLink>
							{
								user && user.userTypeId === CLIENT_USER_TYPE_ID ?
									<React.Fragment>
										<NavLink to="/dashboard/tasks" className={`nav-item nav-link text-secondary ${pathname.includes("/dashboard/") ? "active" : ""}`}>
											Dashboard
										</NavLink>
										<OverlayTrigger
											key={"bottom"}
											placement={"bottom"}
											overlay={
												<Tooltip id={`tooltip-bottom`}>
													Coming Soon
												</Tooltip>
											}
										>
											<Link to="#" className={`nav-item nav-link text-secondary`}>
												Exchange
											</Link>
										</OverlayTrigger>
									</React.Fragment>
									: null
							}

							{
								user && user.userTypeId === ADMIN_USER_TYPE_ID ?
									<NavLink to="/admin/users" className={`nav-item nav-link text-secondary ${pathname.includes("/dashboard/") ? "active" : ""}`}>
										Admin Dashboard
									</NavLink>
									: null
							}
							<div>

							</div>
							<div className="d-flex align-items-center">
								{
									user ?
										isConnected ?
											<div>
												<w3m-button balance="hide" />
											</div> :
											<Deposit buttonType={2} buttonTitle="Connect Wallet" size="sm" buttonClassName="px-3 btn btn-primary btn-sm" />
										:
										<div>
											<Link to="/login" className="nav-item nav-link btn btn-primary m-0" style={{ padding: "5px 15px" }}>
												Login
											</Link>
										</div>
								}
								{
									user ?
										<NavLink
											to="/logout"
											className="nav-item nav-link m-0"
										>
											<MdOutlineLogout className="text-primary ms-3 cursor-pointer" size={22} />
										</NavLink>
										: null
								}
							</div>

						</div>
					</div>

					<div className={`section mobilemenu py-2 d-flex flex-column ${isShowMobilemenu ? "mobilemenu-active" : ""}`}>
						<NavLink
							to="/"
							className={`nav-item nav-link text-secondary my-2 d-flex gap-2 align-items-end`}
							onClick={() => setIsShowMobilemenu(false)}
						>
							<span className="mb-1"><IoHomeOutline size={20} /></span><span className="mb-0 d-block">Home</span>
						</NavLink>
						{
							user ?
								<div>

									{
										user.userTypeId === CLIENT_USER_TYPE_ID ?
											<NavLink
												to="/dashboard/tasks"
												className={`nav-item nav-link text-secondary my-2 d-flex gap-2 align-items-end 
											${pathname.includes("/dashboard/") ? "active" : ""}`}
												onClick={() => setIsShowMobilemenu(false)}
											>
												<span className="mb-1"><LuLayoutDashboard size={20} /></span><span className="mb-0 d-block">Dashboard</span>
											</NavLink>
											: null
									}
									{
										user.userTypeId === ADMIN_USER_TYPE_ID ?
											<NavLink
												to="/admin/users"
												className={`nav-item nav-link text-secondary my-2 d-flex gap-2 align-items-end 
											${pathname.includes("/admin/") ? "active" : ""}`}
												onClick={() => setIsShowMobilemenu(false)}
											>
												<span className="mb-1"><LuLayoutDashboard size={20} /></span><span className="mb-0 d-block">Admin Dashboard</span>
											</NavLink>
											: null
									}
									<Link
										to="#"
										className={`nav-item nav-link text-secondary my-3 d-flex gap-2 align-items-end  `}
									>
										<span style={{marginBottom:"4px"}}><MdCurrencyExchange size={20} /></span>
										<OverlayTrigger
											placement={"bottom"}
											overlay={
												<Tooltip id={`tooltip-bottom`}>
													Coming Soon
												</Tooltip>
											}
										>
											<span className="mb-0">Exchange</span>
										</OverlayTrigger>
									</Link>
									<hr className="mb-2" />
									<NavLink
										to="/logout"
										className="nav-item nav-link text-secondary my-2 d-flex gap-2 align-items-end"
										onClick={() => setIsShowMobilemenu(false)}
									>
										<span className="mb-1"><MdOutlineLogout size={20} /></span><span className="mb-0 d-block">Logout</span>
									</NavLink>
								</div>
								:
								<NavLink
									to="/login"
									className="nav-item nav-link text-secondary my-2 d-flex gap-2 align-items-end"
									onClick={() => setIsShowMobilemenu(false)}
								>
									<span className="mb-1"><MdOutlineLogin size={20} /></span><span className="mb-0 d-block">Login</span>
								</NavLink>
						}

					</div>

				</nav>
			</header>
		</div>
	);
}

export default Header;