
// import { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(utc);
// dayjs.extend(timezone);

// const useCountdown = (targetDate) => {
//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(calculateTimeLeft(targetDate));
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [targetDate]);

//   return timeLeft;
// };

// const calculateTimeLeft = (targetDate) => {
//   const now = dayjs(); // Текущая дата и время
//   const target = dayjs(targetDate); // Целевая дата

//   // Проверяем разницу в миллисекундах
//   const diff = target.diff(now);

//   if (diff <= 0) {
//     return {
//       days: '00',
//       hours: '00',
//       minutes: '00',
//       seconds: '00',
//     };
//   }

//   // Рассчитываем оставшееся время
//   const days = Math.floor(diff / (1000 * 60 * 60 * 24));
//   const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
//   const minutes = Math.floor((diff / (1000 * 60)) % 60);
//   const seconds = Math.floor((diff / 1000) % 60);

//   return {
//     days: String(days).padStart(2, '0'),
//     hours: String(hours).padStart(2, '0'),
//     minutes: String(minutes).padStart(2, '0'),
//     seconds: String(seconds).padStart(2, '0'),
//   };
// };

// export default useCountdown;




import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return timeLeft;
};

const calculateTimeLeft = (targetDate) => {
  const now = dayjs().utc();
  const target = dayjs.utc(targetDate);

  const diff = target.diff(now);

  if (diff <= 0) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return {
    days: String(days).padStart(2, '0'),
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
  };
};

export default useCountdown;
