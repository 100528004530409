import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import AlertService from '../../helpers/services/alertService';
import { ApiService } from '../../helpers/services/apiSrevice';
import MainService from '../../helpers/services/mainService';
import { TOKEN_NAME } from '../../helpers/constants/constants';
import ShareModal from './ShareModal';
import { MdIosShare } from "react-icons/md";
import useWindowSize from '../../components/hooks/useWindowSize';
import useScrollToElement from '../../components/hooks/useScrollToElement';

export default function Referral() {

  const pageSize = 10;
  const [invitationLink, setInvitationLink] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [isShowShareModal, setIsShowShareModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(pageSize);
  const [isShowSpinner, setIsShowSpinner] = useState(false);

  const windowSize = useWindowSize();
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (windowSize?.width <= 991) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [windowSize]);

  useScrollToElement("referral", shouldScroll, 180);

  useEffect(() => {
    getReferrals();
    getInvitationUrl();
  }, []);

  const getReferrals = () => {
    ApiService.getReferrals().then(response => {
      if (response && response.data) {
        let _referrals = response.data.reverse();
        setReferrals(_referrals);
      } else {
        setReferrals([]);
      }
    }).catch(error => {
      AlertService.alert("warning", error);
    });
  }

  const getInvitationUrl = () => {
    ApiService.getInvitationUrl().then(response => {
      if (response && response.data) {
        setInvitationLink(response.data);
      } else {
        setInvitationLink("");
      }
    }).catch(error => {
      AlertService.alert("warning", error);
    });
  }

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      AlertService.alert("success", "Copied!");
    } catch (err) {
      console.error('Failed to copy text to clipboard:', err);
    }
  };

  const loadMore = () => {
    setIsShowSpinner(true);
    setTimeout(() => {
      setItemsPerPage(itemsPerPage + pageSize);
      setIsShowSpinner(false);
    }, 500);
  };

  return (
    <Row id='referral'>
      <ShareModal shareUrl={invitationLink} show={isShowShareModal} handleClose={() => setIsShowShareModal(false)} />
      <Col sm={12}>
        <h4 className='text-white'>Referral</h4>
        <hr />
      </Col>

      <Col sm={12}>
        <div className='p-3 mb-2 info-bg rounded mb-3 text-white'>
          <span className='d-block mb-2'><span style={{ fontWeight: "600" }}>What is Invitation Link?</span></span>
          <span className='d-block ps-2'>
            The invitation link allows you to invite new users to our platform. When a user registers through your link, you earn tokens for their registration and additional rewards based on their deposits.
          </span>
        </div>
      </Col>
      <Col sm={12}>
        <div className='mt-4'>
          <h5 className='text-white'>Invite and Earn with Zelara!</h5>
          <p>Invite your friends and earn rewards! For each person you refer, you will receive <span className='text-primary'>500 {TOKEN_NAME}</span>. Additionally, every time that person makes a deposit, you will earn a <span className='text-primary'>10%</span> reward based on their deposit amount.
          </p>
        </div>
      </Col>
      {
        invitationLink ?
          <Col sm={12}>
            <h5 className='text-white'>Invitation Link</h5>
            <Card className='border mb-4'>
              <Card.Body>
                <div className='d-flex gap-3'>
                  <Button
                    type='button'
                    variant='primary'
                    size='sm'
                    className='w-100'
                    style={{ fontWeight: "600" }}
                    onClick={() => {
                      copyToClipboard(invitationLink);
                    }}
                  >
                    Copy Invitation Link
                  </Button>
                  <Button
                    type='button'
                    variant='primary'
                    size='sm'
                    className='px-3'
                    onClick={() => {
                      setIsShowShareModal(true);
                    }}
                  >
                    <MdIosShare size={18} />
                  </Button>

                </div>
              </Card.Body>
            </Card>
          </Col>
          : null
      }

      {
        referrals && referrals.length ?
          <Col sm={12}>
            <p className='text-white'>Referrals count: <span className='text-primary'>{referrals.length}</span></p>
            <Table striped bordered hover responsive variant="dark">
              <thead>
                <tr>
                  <th className="text-secondary">#</th>
                  <th className="text-secondary text-nowrap">E-mail</th>
                  <th className="text-secondary text-nowrap">Referral Reward</th>
                </tr>
              </thead>
              <tbody>
                {
                  referrals.slice(0, itemsPerPage).map((item, index) => {
                    return <tr key={index}>
                      <td className="text-secondary">{index + 1}</td>
                      <td className="text-secondary">{item.email}</td>
                      <td className="text-secondary"><span>{MainService.formatCurrency(item.referralReward, 2)} {TOKEN_NAME}</span></td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
            {
              itemsPerPage < referrals.length &&
              <div className="text-center">
                <Button variant="primary" className='px-4' size='sm' onClick={loadMore} disabled={isShowSpinner}>
                  {
                    isShowSpinner ? "Loading..." : "More"
                  }
                </Button>
              </div>
            }
          </Col>
          : <Col sm={12} className='text-center'>
            <h4>
              No Referrals Yet
            </h4>
          </Col>
      }

      <Col sm={12}>
        <div className='mt-4'>
          <h5 className='text-white'>How Referrals Work</h5>
          <p>Invite users to our platform using your invitation link. Once the user registers, you will receive tokens as a reward. Additionally, you will earn a percentage of all deposits made by the referred user.</p>

          <h5 className='text-white'>Invitation Link Explanation</h5>
          <p>Share your unique invitation link with others. When someone uses your link to register, you will receive a reward. Keep sharing to maximize your rewards!</p>
        </div>
      </Col>

      <Col sm={12}>
        <div>
          <hr />
          <h5 className='text-white'>Additional Information:</h5>
          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Invite and Earn:</span> Earn tokens for each successful registration through your invitation link.</p>
          <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Ongoing Rewards:</span> Continue earning rewards as long as the referred user remains active and makes deposits.</p>
        </div>
      </Col>
    </Row>
  );
}
