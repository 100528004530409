import { createSlice } from "@reduxjs/toolkit";

const spinersSlice = createSlice({
	name: "spiners",
	initialState: {
		pageSpiners: [],
		buttonSpiners: [],
		modalSpiners: [],
	},
	reducers: {
		addPageSpiner: (state, { payload }) => {
			state.pageSpiners = [...state.pageSpiners, payload];
		},
		removePageSpiner: (state, { payload }) => {
			state.pageSpiners = state.pageSpiners.filter(el => el !== payload);
		},
		addButtonSpiner: (state, { payload }) => {
			state.pageSpiners = [...state.pageSpiners, payload];
		},
		removeButtonSpiner: (state, { payload }) => {
			state.pageSpiners = state.pageSpiners.filter(el => el !== payload);
		},
		addModalSpiner: (state, { payload }) => {
			state.modalSpiners = [...state.modalSpiners, payload];
		},
		removeModalSpiner: (state, { payload }) => {
			state.modalSpiners = state.modalSpiners.filter(el => el !== payload);
		},
	},
});

export const {
	addPageSpiner,
	removePageSpiner,
	addButtonSpiner,
	removeButtonSpiner,
	addModalSpiner,
	removeModalSpiner,
} = spinersSlice.actions;

export default spinersSlice.reducer;
