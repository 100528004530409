import React from "react";
import CountUp from 'react-countup';
import MainService from "../../helpers/services/mainService";

const CountUpComponent = React.memo(({ start, end, cb, duration }) => {
    
    return (
        <CountUp
            start={start}
            end={end}
            decimals={2}
            useEasing={true}
            separator=''
            duration={duration}
            formattingFn={value => MainService.formatCurrency(value, 2)}
            onEnd={() => {
                cb(end);
            }}
        >
            {({ countUpRef }) => (
                <div>
                    <span ref={countUpRef} />
                </div>
            )}
        </CountUp>
    );
});

export default CountUpComponent;
