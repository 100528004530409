// import { useEffect } from 'react';

// const useScrollToElement = (elementId) => {
//   useEffect(() => {
//     const element = document.getElementById(elementId);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     }
//   }, [elementId]);
// };

// export default useScrollToElement;



// import { useEffect } from 'react';

// const useScrollToElement = (elementId, shouldScroll) => {
//   useEffect(() => {
//     if (shouldScroll) {
//       const element = document.getElementById(elementId);
//       if (element) {
//         element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//       }
//     }
//   }, [elementId, shouldScroll]);
// };

// export default useScrollToElement;





// import { useEffect } from 'react';

// const useScrollToElement = (elementId, shouldScroll) => {
//   useEffect(() => {
//     if (shouldScroll) {
//       const element = document.getElementById(elementId);
//       if (element) {
//         element.scrollIntoView({
//           behavior: 'smooth',
//           block: 'start',
//         });
//       }
//     }
//   }, [elementId, shouldScroll]);
// };

// export default useScrollToElement;



import { useEffect } from 'react';

const useScrollToElement = (elementId, shouldScroll, offset = 180) => {
  useEffect(() => {
    if (shouldScroll) {
      const element = document.getElementById(elementId);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    }
  }, [elementId, shouldScroll]);
};

export default useScrollToElement;
