import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AlertService from '../helpers/services/alertService';
import { ApiService } from '../helpers/services/apiSrevice';
import { useSelector } from 'react-redux';
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useSearchParams } from 'react-router-dom';
import GoogleLoginButton from './components/GoogleLoginButton';
import logo_2 from "./../assets/unauth_pages_images/ai_2.jpg"

export default function Registration() {


  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referral = searchParams.get('referral');
  const { user } = useSelector(state => state.user);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    isAgree: false,
  })

  useEffect(() => {
    if(referral){
      localStorage.setItem("referral", referral);
      navigate("/registration");
    }
  }, [referral])

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user])

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.value
    }))
    setIsInvalidSubmit(false);
  }

  const onCheckboxChange = (event, fieldName) => {
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.checked
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.email.trim().length || !values.password.trim().length || isLoading) {
      setIsInvalidSubmit(true);
      return false;
    }
    setIsLoading(true);
    let data = {
      email: values.email,
      password: btoa(values.password),
      referral: referral,
    }
    ApiService.userRegistration(data).then(response => {
      if (response && response.status) {
        setIsRegistrationSuccessful(true);
      }
    }).catch(error => {
      AlertService.alert("warning", error)
    }).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <div>
      <div className="container-fluid hero-header py-lg-5 py-0 mb-5 unauth-container">
        <div className="container py-5">
          <div className="row g-5 align-items-center">

            <Card className='p-0'>

              <CardBody className='p-0'>
                <div className='d-flex'>


                  <div className='w-100 content-block'>
                    {
                      isRegistrationSuccessful ? null : <h1 className="display-4 mb-3">Sign Up</h1>
                    }
                    <div>
                      {
                        isRegistrationSuccessful ?
                          <div>
                            <h2 className='mb-4'>Registration Successful!</h2>
                            <p className='mb-4'>Thank you for registering on our website. We have sent a confirmation link to your email. Please check your inbox and click on the link to verify your email address and complete the registration process.</p>
                          </div>
                          : <Form onSubmit={onSubmit}>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>E-mail</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter e-mail"
                                className={isInvalidSubmit && !values.email.trim().length ? "error-border" : ""}
                                value={values.email}
                                onChange={(event) => onChange(event, "email")}
                              />
                              <small>
                                We'll never share your email with anyone else.
                              </small>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Group controlId="formNewPassword" className='position-relative'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type={isShowPassword ? "text" : "password"}
                                  placeholder="Enter password"
                                  value={values.password}
                                  onChange={(event) => onChange(event, "password")}
                                  className={isInvalidSubmit && !values.password.trim().length ? "error-border" : ""}
                                />
                                <div className='eye' onClick={() => setIsShowPassword(!isShowPassword)}>
                                  {
                                    isShowPassword ?
                                      <IoEyeOff size={20} />
                                      :
                                      <IoEye size={20} />
                                  }
                                </div>
                              </Form.Group>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                              <Form.Check
                                color='red'
                                type="checkbox"
                                checked={values.isAgree}
                                label={
                                  <div>
                                    I agree to the <Link to={"/terms-and-conditions"} target='_blank' className='text-primary'>Terms & Conditions</Link> and  <Link to={"/privacy-policy"} target='_blank' className='text-primary'>Privacy Policy.</Link>
                                  </div>
                                }
                                onChange={(event) => onCheckboxChange(event, "isAgree")}
                              />
                            </Form.Group>

                            {
                              isLoading ?
                                <Button className='w-100 mb-3 px-4' variant="primary" type="submit">
                                  <span>
                                    Loading
                                    <Spinner className='ms-2' animation="border" size='sm' />
                                  </span>
                                </Button>
                                : <Button className='w-100 mb-3' variant="primary" type="submit" disabled={values.isAgree ? false : true}>
                                  Sign Up
                                </Button>
                            }
                          </Form>
                      }
                      {
                        isRegistrationSuccessful ? null :
                          <div>
                            <div className='my-2 d-flex align-items-center justify-content-between gap-2'>
                              <hr className='w-100' />
                              <span className='text-primary'>or</span>
                              <hr className='w-100' />
                            </div>

                            <div className={`mb-4 mt-1 d-flex justify-content-center ${isLoading ? "disabled" : ""}`}>
                              <GoogleLoginButton referral={referral} />
                            </div>
                          </div>
                      }

                      <div className='text-center'>
                        You already have an account ?  <Link to={"/login"} className='text-primary'>Sign In</Link>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 d-none d-lg-block image-block">
                    <div
                      className='bg-image'
                      style={{
                        backgroundImage: `url(${logo_2})`
                      }}>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>



        </div>
      </div>
    </div >
  )
}
