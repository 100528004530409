import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { TOKEN_NAME } from '../../helpers/constants/constants';
import MainService from '../../helpers/services/mainService';
import { useSelector } from 'react-redux';
import { ApiService } from '../../helpers/services/apiSrevice';
import AlertService from '../../helpers/services/alertService';

export default function WithdrawModal({
  isShowWithdrawModal,
  cancel = () => { },
  balanceType, // 'main' or 'staking'
  balance,
  cb = () => { },
}) {

  const { user } = useSelector(state => state.user);
  const [isButtonSpinner, setIsButtonSpinner] = useState(false);
  const [amount, setAmount] = useState("");
  const [walletId, setWalletId] = useState("");

  const onNumberChange = (event, cb, maxValue = balance) => {
    if (event.target.value.includes("e") || event.target.value.includes(".")) {
      return false;
    }
    if (
      event.target.value === "" ||
      (typeof +event.target.value === "number" &&
        +event.target.value >= 0 &&
        +event.target.value <= maxValue)
    ) {
      cb(event.target.value);
    }
  };

  const withdrawBalance = (event) => {
    event && event.preventDefault();
    if (balanceType === 'main') {
      console.log(`To wallet ID: ${walletId}`);

    } else {
      setIsButtonSpinner(true);

      const data = {
        userId: user.id,
        targetBalanceType: 1,
        coinAmount: Math.floor(+amount) || 0,
      };

      ApiService.tokenSwap(data).then((response) => {
        AlertService.alert("success", "Transfer from staking to main balance was successful. Your main balance has been updated.");
        if (response && response.data) {
          cb && cb(response.data);
        }

      }).catch(error => {
        AlertService.alert("warning", error);
      }).finally(() => {
        _cancel();
      });
    }
  }

  const _cancel = () => {
    setAmount("");
    setWalletId("");
    setIsButtonSpinner(false);
    cancel();
  }

  return (
    <Modal show={isShowWithdrawModal} onHide={_cancel} animation={true} className='text-dark' centered>
      <Form onSubmit={withdrawBalance}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw from {balanceType === 'main' ? 'Main Balance' : 'Staking Balance'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small className='d-block mb-2'>Your {balanceType === 'main' ? "" : "available"} {balanceType} balance is <b>{MainService.formatCurrency(balance, 2)} {TOKEN_NAME}</b>.</small>
          {
            balanceType === 'main' &&
            <div className='mb-2'>
              <label className='form-label' htmlFor='walletId'>Wallet ID</label>
              <input
                id='walletId'
                placeholder="Enter wallet ID"
                className='form-control'
                autoFocus
                autoComplete="off"
                value={walletId}
                onChange={(e) => setWalletId(e.target.value)}
              />
            </ div>
          }

          <div>
            <label className='form-label' htmlFor='amount'>Please enter the amount you want to withdraw.</label>
            <input
              id='amount'
              placeholder="Enter amount"
              className='form-control'
              value={amount}
              autoComplete="off"
              autoFocus={balanceType === 'main' ? false : true}
              onChange={(event) => onNumberChange(event, setAmount)}
            />
            <div className='d-flex justify-content-end'>
              <small
                className='text-muted cursor-pointer'
                onClick={() => setAmount(Math.floor(balance))}
              >
                max
              </small>
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={_cancel}>
            Cancel
          </Button>
          {
            isButtonSpinner ?
              <Button type='button' variant="primary" disabled>
                <span className='me-1'>Loading...</span>
                <Spinner animation="border" size="sm" />
              </Button>
              : <Button
                type='submit'
                variant="primary"
                disabled={+amount > 0 && (balanceType === 'main' ? walletId : true) ? false : true}
                onClick={withdrawBalance}
              >
                Withdraw
              </Button>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
