import { createSlice } from "@reduxjs/toolkit";

const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}


const userSlice = createSlice({
	name: "user",
	initialState: {
		user: isJson(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null,
		token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
	},
	reducers: {
		setUserData: (state, { payload }) => {
			state.user = payload;
		},
		updateUserData: (state, { payload }) => {
			state.user = payload;
		},
		setToken: (state, { payload }) => {
			state.token = payload;
		},
	},
});

export const {
	setUserData,
	updateUserData,
	setToken,
} = userSlice.actions;

export default userSlice.reducer;
