import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountForm from './AccountForm';
import ChangePasswordForm from './ChangePasswordForm';
import useScrollToElement from '../../components/hooks/useScrollToElement';
import useWindowSize from '../../components/hooks/useWindowSize';

export default function Settings() {

  const windowSize = useWindowSize();
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (windowSize?.width <= 991) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [windowSize]);

  useScrollToElement("settings", shouldScroll, 180);

  return (
    <Row id='settings'>
      <Col sm={12}>
        <h4 className='text-white'>Settings</h4>
        <hr />

        <div className='custom-tabs'>
          <Tabs
            defaultActiveKey="account"
            className="mb-3"
          >
            <Tab eventKey="account" title="Account">
              <AccountForm />
            </Tab>
            <Tab eventKey="change_password" title="Change Password">
              <ChangePasswordForm />
            </Tab>
          </Tabs>
        </div>

      </Col>

    </Row>
  )
}
