import React, { useEffect } from 'react';

const GlobalErrorHandler = ({ children }) => {

    useEffect(() => {
        window.onerror = function (message, source, lineno, colno, error) {
            if (error && error.name === "ZodError") {
                return true;
                
            }
            console.error("Global error:", {
                message,
                source,
                lineno,
                colno,
                error
            });

            return false;
        };

        window.addEventListener("unhandledrejection", function (event) {
            if (event.reason && event.reason.name === "ZodError") {
                return true; // Скрываем ZodError
            }
            console.error("Unhandled Promise rejection:", event.reason);
        });

        return () => {
            window.onerror = null;
            window.removeEventListener("unhandledrejection", null);
        };
    }, []);

    return (
        <>
            {children}
        </>
    );
};

export default GlobalErrorHandler;
