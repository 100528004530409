import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApiService } from '../helpers/services/apiSrevice';
import AlertService from '../helpers/services/alertService';
import logo_1 from "./../assets/unauth_pages_images/ai_1.jpg"

export default function ForgotPassword() {

  const navigate = useNavigate();
  const { user } = useSelector(state => state.user);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [values, setValues] = useState({
    email: "",
  })

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user])

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.value
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.email.trim().length || isLoading) {
      setIsInvalidSubmit(true);
      return false;
    }
    setIsLoading(true);
    ApiService.forgotPassword(values.email).then(response => {
      setIsRequestSuccessful(true);
    }).catch(error => AlertService.alert("warning", error)).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div>
      <div className="container-fluid hero-header py-lg-5 py-0 mb-5  unauth-container">
        <div className="container py-5">
          {/* <div className="col-lg-6 d-none d-lg-block">
            <div className='animated fadeIn'>
              <img className="img-fluid animated pulse d-block" style={{ animationDuration: "3s", maxWidth: "300px", margin: "0 auto" }} src={logo} alt="/" />
            </div>
          </div> */}
          <Card className='p-0'>
            <CardBody className='p-0' style={{ height: "400px" }}>
              <div className='d-flex h-100'>

                <div className="w-100 d-none d-lg-block image-block">
                  <div
                    className='bg-image'
                    style={{
                      backgroundImage: `url(${logo_1})`
                    }}>
                  </div>
                </div>

                <div className='w-100 content-block'>
                  {
                    isRequestSuccessful ? null :
                      <h1 className="display-4 mb-3 text-nowrap">Forgot Password ?</h1>
                  }
                  <div>
                    {
                      isRequestSuccessful ?
                        <div>
                          <h2 className='mb-4'>Password Reset Request Sent!</h2>
                          <p className='mb-4'>We have sent a password reset link to your email. Please check your inbox and follow the link to reset your password.</p>
                        </div>
                        :
                        <Form onSubmit={onSubmit}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter e-mail"
                              className={isInvalidSubmit && !values.email.trim().length ? "error-border" : ""}
                              value={values.email}
                              onChange={(event) => onChange(event, "email")}
                            />
                            <small>After entering your email, we will send you a verification link. Please check your inbox and follow the instructions to reset your password.</small>
                          </Form.Group>
                          {
                            isLoading ?
                              <Button className=' mb-3 px-4 w-100' variant="primary" type="submit">
                                <span>
                                  Loading
                                  <Spinner className='ms-2' animation="border" size='sm' />
                                </span>
                              </Button>
                              : <Button className=' mb-3 px-4 w-100' variant="primary" type="submit" disabled={isLoading}>
                                Send
                              </Button>
                          }
                        </Form>
                    }
                    <div className='text-center'>
                      You already have an account ? <Link to={"/login"} className='text-primary'>Sign In</Link>
                    </div>
                  </div>
                </div>

              </div>

            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}
