import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { TOKEN_NAME } from '../../helpers/constants/constants';
import MainService from '../../helpers/services/mainService';
import { ApiService } from '../../helpers/services/apiSrevice';
import AlertService from '../../helpers/services/alertService';
import { useSelector } from 'react-redux';


export default function StakingModal({
    isShowStakingModal,
    cancel = () => { },
    mainBalance,
    cb = () => { },
}) {
    const { user } = useSelector(state => state.user);
    const [isShowButtonSpinner, setIsButtonSpinner] = useState(false);
    const [amount, setAmount] = useState("");

    const onNumberChange = (event, cb, maxValue = mainBalance) => {
        if (event.target.value.includes("e") || event.target.value.includes(".")) {
            return false;
        }
        if (
            event.target.value === "" ||
            (typeof +event.target.value === "number" &&
                +event.target.value >= 0 &&
                +event.target.value <= maxValue)
        ) {
            cb(event.target.value);
        }
    };

    const stakeBalance = (event) => {
        event && event.preventDefault();
        if (isShowButtonSpinner) { return false; }
        setIsButtonSpinner(true);

        const data = {
            userId: user.id,
            targetBalanceType: 2,
            coinAmount: +amount || 0,
        };

        ApiService.tokenSwap(data).then((response) => {
            AlertService.alert("success", "Your staking was successful. Your balance has been updated.");
            if (response && response.data) {
                cb && cb(response.data);
            }
        }).catch(error => {
            AlertService.alert("warning", error);
        }).finally(() => {
            _cancel();
        });
    }


    const _cancel = () => {
        setAmount("");
        setIsButtonSpinner(false);
        cancel();
    }

    return (
        <Modal show={isShowStakingModal} onHide={_cancel} animation={true} className='text-dark' centered>
            <Form onSubmit={stakeBalance}>
                <Modal.Header closeButton>
                    <Modal.Title >Stake Your Balance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small className='d-block mb-2'>Your main balance is <b>{MainService.formatCurrency(mainBalance, 2)} {TOKEN_NAME}</b>.</small>
                    <label className='form-label' htmlFor='amount'>Please enter the amount you want to stake.</label>
                    <input
                        id='amount'
                        placeholder="Enter amount"
                        className='form-control'
                        value={amount}
                        autoFocus
                        autoComplete="off"
                        onChange={(event) => onNumberChange(event, setAmount)}
                    />
                    <div className='d-flex justify-content-end'>
                        <small
                            className='text-muted cursor-pointer'
                            onClick={() => setAmount(mainBalance)}
                        >
                            max
                        </small>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={_cancel}>
                        Cancel
                    </Button>
                    {
                        isShowButtonSpinner ?
                            <Button type='button' variant="primary" disabled>
                                <span className='me-1'>Loading...</span>
                                <Spinner animation="border" size="sm" />
                            </Button>
                            : <Button
                                type='submit'
                                variant="primary"
                                disabled={+amount > 0 ? false : true}
                                onClick={stakeBalance}
                            >
                                Stake
                            </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
