import store from "../../store/index";
import {
	addButtonSpiner,
	addModalSpiner,
	addPageSpiner,
	removeButtonSpiner,
	removeModalSpiner,
	removePageSpiner,
} from "../../store/reducers/SpinersReducer";
import { v4 as uuid } from "uuid";
import { ERROR_KEY } from "../constants/constants";
import AlertService from "./alertService"

class MainService {
	async readFile(file, validFileTypes = null, progressCallback) {
		const CHUNK_SIZE = 1024 * 1024 * 10000; // 10000 MB -> 10GB
		let offset = 0;
		const fileSize = file.size;
		const chunks = [];
		// Validate file extension
		const fileName = file.name;
		const lastDotIndex = fileName.lastIndexOf(".");
		const fileExtention =
			lastDotIndex !== -1
				? fileName.substring(lastDotIndex + 1).toLowerCase()
				: "";
		const isValid = validFileTypes.includes(fileExtention);
		if (!isValid) {
			throw new Error("Invalid file extension");
		}

		while (offset < fileSize) {
			const end = Math.min(offset + CHUNK_SIZE, fileSize);
			const chunk = await this.readChunk(file, offset, end, progressCallback);
			chunks.push(chunk);
			offset = end;
		}

		const videoBlob = new Blob(chunks, { type: file.type });
		const videoUrl = URL.createObjectURL(videoBlob);
		return videoUrl;
	}

	static convertUTCDateToLocalDate(date) {
		let offset = date.getTimezoneOffset() / 60;
		let hours = date.getHours();

		date.setHours(hours - offset);
		return date;
		// return moment(date).local().format('YYYY-MM-DD HH:mm:ss');
	}


	async readChunk(file, start, end, cb) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			const blob = file.slice(start, end);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error); // Pass the error to reject()
			reader.readAsArrayBuffer(blob);
			if (cb) {
				reader.onprogress = function (data) {
					if (data.lengthComputable) {
						var progress = parseInt((data.loaded / data.total) * 100, 10);
						cb(progress);
					}
				};
			}
		});
	}

	static isJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	static isValidField(fieldValue, fieldName) { }

	static onEditorChange = ({ event = {}, cb = () => { } }) => {
		const newRichText = event.editor.getData();
		cb(event);
	};

	static onCheckboxChange = ({
		event,
		fieldName,
		setIsInvalidSubmit = () => { },
		setValues = () => { },
	}) => {
		if (typeof event.target.checked === "boolean") {
			setValues(values => ({
				...values,
				[fieldName]: event.target.checked,
			}));
			setIsInvalidSubmit(false);
		}
	};

	static onNumberChange = ({
		event,
		cb = () => { },
		setIsInvalidSubmit = () => { },
		maxValue = Infinity,
	}) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsInvalidSubmit(false);
		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			cb(values => ({
				...values,
				[event.target.name]: event.target.value,
			}));
		}
	};

	static onSelectOptionChange = ({
		item,
		key,
		cb = () => { },
		setIsInvalidSubmit = () => { },
	}) => {
		if (!item) return null;
		setIsInvalidSubmit(false);
		cb(values => ({ ...values, [key]: item.value }));
	};

	static onChange = ({
		event,
		fieldName,
		cb = () => { },
		setIsInvalidSubmit = () => { },
		maxLength = Infinity,
	}) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		cb(values => ({
			...values,
			[fieldName || event.target.name]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	static collApi = ({
		api,
		cb,
		buttonSpiner = null,
		isModal = false,
		getFail = null,
	}) => {
		const spinerId = uuid();
		if (buttonSpiner) {
			store.dispatch(addButtonSpiner(buttonSpiner));
		} else if (isModal) {
			store.dispatch(addModalSpiner(spinerId));
		} else {
			store.dispatch(addPageSpiner(spinerId));
		}
		return api()
			.then(response => {
				cb(response);
			})
			.catch(error => {
				if (getFail) {
					getFail(error);
				} else {
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
				}
			})
			.finally(() => {
				store.dispatch(removeButtonSpiner(spinerId));
				store.dispatch(removePageSpiner(spinerId));
				store.dispatch(removeModalSpiner(spinerId));
			});
	};


	static getRandomNumber_1_100 = () => {
		return Math.floor(Math.random() * 100) + 1;
	}

	static getRandomNumber_1_5000 = () => {
		return Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000;
	}

	static formatCurrency = (number = 0, decimalPlaces = 0) => {
		return Number(number).toLocaleString('en-US', {
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		});
	}


	static shortenAddress = (address, start = 8, end = 4) => {
		// Ensure the address is a string and has a valid length
		if (typeof address !== 'string' || !address || (address && address.length < 12)) {
			return ""
		}

		// Extract parts of the address
		const prefix = address.slice(0, start);
		const suffix = address.slice(-end);

		// Concatenate parts with ellipsis in the middle
		return `${prefix}...${suffix}`;
	};

	// static formatWithSuffix = (value, decimal = 0) => {
	// 	if (!value) { return 0; }
	// 	if (value >= 1000000000) {
	// 		return (value / 1000000000).toFixed(decimal) + 'B'; // "B" для миллиардов
	// 	} else if (value >= 1000000) {
	// 		return (value / 1000000).toFixed(decimal) + 'M'; // "M" для миллионов
	// 	} else if (value >= 1000) {
	// 		return (value / 1000).toFixed(decimal) + 'K'; // "K" для тысяч
	// 	} else {
	// 		return value.toString(); // Возвращает число как строку без суффикса
	// 	}
	// };
	static formatWithSuffix = (value, decimal = 2) => {
		if (!value) { return 0; }

		const divisor = (div) => {
			// Оставляем нужное количество знаков после запятой
			let result = value / div;
			return Math.floor(result * Math.pow(10, decimal)) / Math.pow(10, decimal); // Обрезаем лишние цифры, не округляя
		};

		if (value >= 1000000000) {
			return divisor(1000000000).toString() + 'B'; // "B" для миллиардов
		} else if (value >= 1000000) {
			return divisor(1000000).toString() + 'M'; // "M" для миллионов
		} else if (value >= 1000) {
			return divisor(1000).toString() + 'K'; // "K" для тысяч
		} else {
			return value.toString(); // Возвращает число как строку без суффикса
		}
	};


}

export default MainService;
