import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { TOKEN_NAME } from '../../helpers/constants/constants'
import MainService from "./../../helpers/services/mainService"
import StakingModal from './StakingModal';
import WithdrawModal from './WithdrawModal';
import { ApiService } from '../../helpers/services/apiSrevice';
import AlertService from '../../helpers/services/alertService';
import CountUpComponent from './CountUpComponent';
import Deposit from './Deposit';
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import useWindowSize from '../../components/hooks/useWindowSize';
import useScrollToElement from '../../components/hooks/useScrollToElement';

export default function Finance() {

  const windowSize = useWindowSize();
  const [isShowStakingModal, setIsShowStakingModal] = useState(false);
  const [mainBalance, setMainBalance] = useState(0);
  const [stakingBalance, setStakingBalance] = useState(0);
  const [stakingAvailableBalance, setStakingAvailableBalance] = useState(0);

  const [usdtBalance, setUsdtBalance] = useState(0);
  const [prevUsdtBalance, setPrevUsdtBalance] = useState(0);
  const [prevMainBalance, setPrevMainBalance] = useState(0);
  const [prevStakingBalance, setPrevStakingBalance] = useState(0);
  const [isShowWithdrawModal, setIsShowWithdrawModal] = useState(false);
  const [balanceType, setBalanceType] = useState("");
  const [hasError, setHasError] = useState(false);
  const [firstRequest, setFirstRequest] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);

  const [isShowUsdtBalance, setIsShowUsdtBalance] = useState(false);
  const [isShowMainBalance, setIsShowMainBalance] = useState(false);
  const [isShowStakingBalance, setIsShowStakingBalance] = useState(false);

  useEffect(() => {
    if (windowSize?.width <= 991) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [windowSize]);

  useScrollToElement("finance", shouldScroll, 180);

  const getBalances = () => {
    ApiService.getUserBalances().then(response => {
      if (response && response.data) {
        setMainBalance(+response.data.defaultBalance || 0);
        setStakingBalance(+response.data.stakingBalance || 0);
        setStakingAvailableBalance(+response.data.stakingAvailableBalance || 0);
        setFirstRequest(true);
      }
    }).catch(error => {
      AlertService.alert("warning", error);
      setHasError(true);
    });
  }

  useEffect(() => {
    getBalances();
    checkShowBalances();
  }, []);

  useEffect(() => {
    if (!hasError) {
      const balanceInterval = setInterval(getBalances, 20000);
      return () => clearInterval(balanceInterval);
    }
  }, [hasError]);

  const checkShowBalances = () => {
    let _isShowMainBalance = localStorage.getItem("isShowMainBalance");
    let _isShowStakongBalance = localStorage.getItem("isShowStakingBalance");
    if (_isShowMainBalance) {
      setIsShowMainBalance(true);
    }
    if (_isShowStakongBalance) {
      setIsShowStakingBalance(true);
    }
  }

  const onChangeShowMainBalance = () => {
    if (!isShowMainBalance) {
      localStorage.setItem("isShowMainBalance", "1");
      setIsShowMainBalance(true);
    } else {
      localStorage.removeItem("isShowMainBalance");
      setIsShowMainBalance(false);
    }
  }

  const onChangeShowStakingBalance = () => {
    if (!isShowStakingBalance) {
      localStorage.setItem("isShowStakingBalance", "1");
      setIsShowStakingBalance(true);
    } else {
      localStorage.removeItem("isShowStakingBalance");
      setIsShowStakingBalance(false);
    }
  }

  const onChangeShowUsdtBalance = () => {
    if (!isShowUsdtBalance) {
      localStorage.setItem("isShowUsdtBalance", "1");
      setIsShowUsdtBalance(true);
    } else {
      localStorage.removeItem("isShowUsdtBalance");
      setIsShowUsdtBalance(false);
    }
  }

  const cancel = () => {
    setIsShowStakingModal(false);
    setIsShowWithdrawModal(false);
    setBalanceType("");
  }

  return (
    <div id='finance'>
      <WithdrawModal
        isShowWithdrawModal={isShowWithdrawModal}
        cancel={cancel}
        balance={balanceType === "main" ? mainBalance : stakingAvailableBalance}
        cb={(data) => {
          setMainBalance(+data.defaultBalance || 0);
          setStakingBalance(+data.stakingBalance || 0);
          setStakingAvailableBalance(+data.stakingAvailableBalance || 0);
        }}
        balanceType={balanceType}
      />
      <StakingModal
        isShowStakingModal={isShowStakingModal}
        cancel={cancel}
        mainBalance={mainBalance}
        cb={(data) => {
          setMainBalance(+data.defaultBalance || 0);
          setStakingBalance(+data.stakingBalance || 0);
          setStakingAvailableBalance(+data.stakingAvailableBalance || 0);
        }}
      />
      <Row>
        <Col sm={12}>
          <h4 className='text-white d-flex justify-content-between'>
            <span>Finance</span>


          </h4>
          <hr />
        </Col>

        <Col sm={12}>
          <div className='p-3 mb-2 info-bg rounded mb-3 text-white'>
            <span className='d-block mb-2'>
              <span style={{ fontWeight: "600" }}>Here's a snapshot of your financial status.</span>
            </span>
            <span className='d-block ps-2'>•&nbsp;
              <span style={{ fontWeight: "600" }}>Main Balance</span>: Shows the total {TOKEN_NAME} available for immediate use.
            </span>
            <span className='d-block ps-2'>•&nbsp;
              <span style={{ fontWeight: "600" }}>Staking Balance</span>: Displays the {TOKEN_NAME} you've staked to earn rewards, accruing <span className='text-primary' style={{ fontWeight: '600' }}>1.5% daily</span>.
            </span>
            {/* <span className='d-block ps-2'>•&nbsp;
              <span style={{ fontWeight: "600" }}>USDT Balance</span>: ?????
            </span> */}
          </div>
        </Col>

        <Col md={6}>
          <div className='d-flex mt-3 align-items-start justify-content-between'>
            <h5 className='text-white'>Main Balance</h5>
            <span className='cursor-pointer me-1' onClick={onChangeShowMainBalance}>
              {
                !isShowMainBalance ?
                  <IoEyeOff size={20} />
                  :
                  <IoEye size={20} />
              }
            </span>
          </div>
          <Card className='border'>
            <Card.Body>
              <h5 className={`d-flex gap-2 mb-4 text-white ${isShowMainBalance ? "d-block" : "d-none"}`}>
                {/* {MainService.formatCurrency(mainBalance, 2)} {TOKEN_NAME} */}

                <CountUpComponent
                  start={prevMainBalance}
                  end={mainBalance}
                  cb={setPrevMainBalance}
                  duration={firstRequest ? 2 : 9}
                />
                {TOKEN_NAME}
              </h5>
              <h5 className={`text-white ${isShowMainBalance ? "d-none" : "d-block"}`} style={{ fontSize: "32px", letterSpacing: "2px" }}>******</h5>
              <div className='d-flex justify-content-end gap-2 '>
                <Button
                  variant="warning"
                  size='sm'
                  onClick={() => {
                    setIsShowWithdrawModal(true);
                    setBalanceType("main");
                  }}
                  // disabled={!mainBalance}
                  disabled={true}
                >
                  Withdraw
                </Button>
                <Button
                  variant="success"
                  size='sm'
                  onClick={() => {
                    setIsShowStakingModal(true);
                  }}
                  disabled={!mainBalance}
                >
                  Staking
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <div className='d-flex mt-3 align-items-start justify-content-between'>
            <h5 className='text-white'>Staking Balance</h5>
            <span className='cursor-pointer me-1' onClick={onChangeShowStakingBalance}>
              {
                !isShowMainBalance ?
                  <IoEyeOff size={20} />
                  :
                  <IoEye size={20} />
              }
            </span>
          </div>
          <Card className='border'>
            <Card.Body>

              <h5 className={`mb-4 d-flex gap-2 text-white ${isShowStakingBalance ? "d-block" : "d-none"}`}>
                <CountUpComponent
                  start={prevStakingBalance}
                  end={stakingBalance}
                  cb={setPrevStakingBalance}
                  duration={firstRequest ? 2 : 9}
                />
                {TOKEN_NAME}
              </h5>
              <h5 className={`text-white ${isShowStakingBalance ? "d-none" : "d-block"}`} style={{ fontSize: "32px", letterSpacing: "2px" }}>******</h5>

              <div className='d-flex justify-content-end gap-2'>
                <Button
                  variant="warning"
                  size='sm'
                  onClick={() => {
                    setIsShowWithdrawModal(true);
                    setBalanceType("staking");
                  }}
                  disabled={!stakingBalance}
                >
                  Withdraw
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12}>
          <div className='d-flex mt-3 align-items-start justify-content-between'>
            <h5 className='text-white'>USDT Balance</h5>
            <span className='cursor-pointer me-1' onClick={onChangeShowUsdtBalance}>
              {
                !isShowUsdtBalance ?
                  <IoEyeOff size={20} />
                  :
                  <IoEye size={20} />
              }
            </span>
          </div>
          <Card className='border'>
            <Card.Body>

              <h5 className={`mb-4 d-flex gap-2 text-white ${isShowUsdtBalance ? "d-block" : "d-none"}`}>
                <CountUpComponent
                  start={prevUsdtBalance}
                  end={usdtBalance}
                  cb={setPrevUsdtBalance}
                  duration={firstRequest ? 2 : 9}
                />
                USDT
              </h5>
              <h5 className={`text-white ${isShowUsdtBalance ? "d-none" : "d-block"}`} style={{ fontSize: "32px", letterSpacing: "2px" }}>******</h5>

              <div className='d-flex justify-content-end gap-2'>
                <Button
                  variant="success"
                  size='sm'
                  disabled
                  onClick={() => { }}
                >
                  Deposit
                </Button>
                <Button
                  variant="warning"
                  size='sm'
                  disabled
                  onClick={() => { }}
                >
                  Withdraw
                </Button>
                <Button
                  variant="primary"
                  size='sm'
                  disabled
                  onClick={() => { }}
                >
                  Trade
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12}>
          <div className='mt-4'>
            <h5 className='text-white'>Main Balance</h5>
            <div>
              <div className='mb-2'>
                <span>
                  This is the total amount of {TOKEN_NAME} you have available for withdrawal or transfer. It represents your current liquidity and can be used for transactions or investment opportunities.
                </span>
                <small className='text-primary d-block mt-1'>
                  Main balance withdrawal will be available either after second round or once there are 100,000 users.
                </small>
              </div>
              <div className='d-block border p-3 rounded my-3 text-white'>
                <span> You will be able to make a deposit after clicking the deposit button and connecting your wallet.</span>
                <Deposit buttonType={2} buttonTitle="Deposit" size="sm" buttonVariant="warning" buttonClassName="mt-2 px-3" blockClassName="d-flex justify-content-end mt-2" />
              </div>
            </div>

            <h5 className='text-white'>Staking Balance</h5>
            <p className='mb-0'>
              Represents the {TOKEN_NAME} that you have staked. By staking, you commit your funds to earn additional rewards or interest, with a daily return of <span className='text-primary' style={{ fontWeight: "600" }}>1.5%</span>, significantly boosting your earnings over time.
            </p>
            {/* <h5 className='text-white'>USDT Balance</h5>
            <p className='mb-0'>
              ???
            </p> */}
          </div>
        </Col>
        <Col sm={12}>
          <div>
            <hr />
            <h5 className='text-white'>Actions You Can Take:</h5>
            <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Withdraw</span>: Transfer funds from your main balance to an external wallet or from your staking balance back to your main balance. Ensure you provide the correct wallet ID for external transfers.</p>
            <p className='d-block ps-2'>• <span style={{ fontWeight: "600" }}>Stake</span>: Increase your staking balance by entering the amount you wish to stake. This helps you earn additional benefits over time.</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}
