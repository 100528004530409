import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

export default function TermsConditions() {

  useEffect(() => {
    scrollTop();
  }, [])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  
  return (
    <div className='section mt-3'>

      <div className='container-fluid py-2 mb-5'>
        <div className="row">

          <div className="col-12">
            <div className="h-100">
              <div className="col-12 text-center mb-5">
                <h2 className="display-6 text-primary">Terms and Conditions</h2>
                <p className="fs-5 mb-5">These Terms and Conditions govern your use of our services. By using our services, you agree to comply with these terms. If you do not agree with any part of these terms, you should not use our services.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Definition of Terms
                </h4>
                <p>In these Terms and Conditions, the following terms have the meanings set forth below:</p>
                <div className="ps-3">
                  <p className="mb-1">• <b>Service:</b> Refers to the services provided by our company, including the purchase of ZLR tokens.</p>
                  <p className="mb-1">• <b>ZLR Tokens:</b> Digital tokens that users can purchase and use within our platform.</p>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Services Provided
                </h4>
                <p>Our project allows users to purchase ZLR tokens. These tokens can be used for various purposes within our service. Details on how and where ZLR tokens can be used will be provided on our website.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  User Responsibilities
                </h4>
                <p>Users are required to adhere to the following rules:</p>
                <div className="ps-3">
                  <p className="mb-1">• <b>Prohibited Actions:</b> Users may not use our services for illegal activities, infringement of others' rights, or misuse of our website’s functionality.</p>
                  <p className="mb-1">• <b>Responsibility:</b> Users are responsible for their actions on the site and for the security of their account credentials. We are not liable for any losses resulting from the misuse of account credentials by users.</p>
                </div>
              </div>
              
              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  User Content
                </h4>
                <p>Users may submit content through our services, but retain ownership of such content. However, by submitting content, users grant us a worldwide, non-exclusive, royalty-free license to use, display, and distribute such content in connection with our services.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Limitation of Liability
                </h4>
                <p>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including lost profits, arising out of or related to your use of our services.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Company Rights and Responsibilities
                </h4>
                <p>We reserve the following rights and responsibilities:</p>
                <div className="ps-3">
                  <p className="mb-1">• <b>Service Changes:</b> We may modify, suspend, or discontinue our services at any time without prior notice.</p>
                  <p className="mb-1">• <b>Quality Assurance:</b> We strive to maintain the quality and availability of our services but do not guarantee that the services will be uninterrupted or error-free.</p>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Return and Cancellation Policy
                </h4>
                <p>Currently, returns and cancellations are not permitted. All token purchases are final. Please ensure you review all details before making a purchase.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Jurisdiction and Applicable Law
                </h4>
                <p>In the event of any disputes or disagreements related to the use of our service, the laws of the country in which our company is registered will apply. All disputes will be handled in the courts of that jurisdiction.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Changes to Terms
                </h4>
                <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and users will be notified of such changes via email.</p>
              </div>

              <div className="mb-4">
                <h4 className="text-primary fw-400">
                  Privacy Policy
                </h4>
                <p>Your use of our services is also governed by our <Link className='text-primary' to="/privacy-policy">Privacy Policy</Link>. Please review it to understand how we collect, use, and protect your personal information.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
