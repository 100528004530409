import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { FaArrowUp } from "react-icons/fa";
import { router } from "./pages/router";
import { RouterProvider } from "react-router-dom";
import WagmiComponent from "./WagmiComponent.js";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "./helpers/services/apiSrevice.js";
import { setRounds } from "./store/reducers/FinanceReducer.js";
import AlertService from "./helpers/services/alertService.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GlobalErrorHandler from "./GlobalErrorHandler.js";
import { setToken, setUserData } from "./store/reducers/UserReducer.js";

const App = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getRounds();
  }, [])

  const getRounds = async () => {
    try {
      const response = await ApiService.getRounds();
      if (response && response.data) {
        dispatch(setRounds(response.data));
      } else {
        dispatch(setRounds([]));
      }
    } catch (error) {
      AlertService.alert("warning", error)
    }
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
    <GlobalErrorHandler>
      <GoogleOAuthProvider
        clientId={clientId}
      >
        <WagmiComponent>
          <RouterProvider router={router()} />
        </WagmiComponent>
      </GoogleOAuthProvider>

      <ToastContainer theme="dark" autoClose={5000} />
      {
        scrolled ?
          <span className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top d-flex" onClick={scrollTop}>
            <FaArrowUp />
          </span>
          : null
      }
    </GlobalErrorHandler>
  </div>
}
export default App